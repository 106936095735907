import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicantApi } from '@web/shared/data-access/model';
import { LoggedInGuard } from '@web/web/core/auth/data-access';
import { Promise } from 'cypress/types/cy-bluebird';
import { Observable, of } from 'rxjs';
import { ApplicantAuthViewModel } from '../viewmodel/applicant-auth.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class ApplicantLoggedInGuard extends LoggedInGuard<ApplicantApi.ApplicantLoginResponseDto> {
  protected constructor(applicantAuthViewModel: ApplicantAuthViewModel, router: Router) {
    super(applicantAuthViewModel, router, 'dashboard');
  }

  public override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Object.keys(route.queryParams).includes('jobId')) {
      this.router.navigate([`/external-application/${route.queryParams['jobId']}`], {
        queryParams: { ...route.queryParams, jobId: undefined },
        queryParamsHandling: 'merge',
      });

      return of(true);
    } else {
      const hasDataStoredLocally = this.authViewModel.getLoginData();

      if (hasDataStoredLocally) {
        return this.router.createUrlTree([this.homeRoute], { queryParamsHandling: 'preserve' });
      }

      return true;
    }
  }
}
