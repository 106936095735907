export namespace GoogleJobPostingApi {
  export interface JobPosting {
    '@context': string;
    '@type': string;
    title: string;
    description: string;
    datePosted: Date;
    validThrough: Date;
    hiringOrganization: JobPostingHiringOrganization;
    jobLocation: JobPostingLocation;
    baseSalary?: JobPostingSalary;
    employmentType?: JobPostingEmploymentType | JobPostingEmploymentType[];
    identifier?: JobPostingIdentifier;
  }

  export interface JobPostingHiringOrganization {
    '@type': string;
    name: string;
    sameAs?: string;
    logo?: string;
  }

  export interface JobPostingLocation {
    '@type': string;
    address: {
      '@type': string;
      streetAddress: string;
      addressLocality: string;
      addressRegion: string;
      postalCode: string;
      addressCountry: string;
    };
  }

  export interface JobPostingSalary {
    '@type': string;
    currency: string;
    value: {
      '@type': string;
      value: number;
      unitText: JobPostingSalaryFrequency;
    };
  }

  export interface JobPostingIdentifier {
    '@type': string;
    name: string;
    value: string;
  }

  export enum JobPostingSalaryFrequency {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
  }

  export enum JobPostingEmploymentType {
    FULL_TIME = 'FULL_TIME',
    PART_TIME = 'PART_TIME',
    CONTRACTOR = 'CONTRACTOR',
    TEMPORARY = 'TEMPORARY',
    INTERN = 'INTERN',
    VOLUNTEER = 'VOLUNTEER',
    PER_DIEM = 'PER_DIEM',
    OTHER = 'OTHER',
  }

  export interface GoogleMetadata {
    title: string;
    description: string;
  }

  export interface MetaDataWrapper {
    jobPosting: JobPosting;
    googleMetadata: GoogleMetadata;
  }
}
