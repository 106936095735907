import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'wh-s-progress-bar',
  imports: [CommonModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {}
