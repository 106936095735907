import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FeatherIconType } from '@web/web/shared/ui/feather-icon';
import { TagComponent } from '@web/web/shared/ui/tag';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-squared-chip',
  imports: [CommonModule, TagComponent, FeatherModule],
  template: `
    <div class="chip-wrapper" [ngStyle]="{ 'background-color': backgroundColor || 'white' }">
      <i-feather *ngIf="icon" class="mr-1" [name]="icon" [ngStyle]="{ color: fontColor || 'gray' }"></i-feather>

      <p [ngStyle]="{ color: fontColor || 'gray' }">{{ label }}</p>
    </div>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;
      @use 'shared-root' as root-var;

      :host {
        margin: 0.25rem !important;
        white-space: pre;
        display: inline-block;
        width: max-content;

        & > .chip-wrapper {
          @include mx.flex-center-x();

          color: root-var.$secondary;
          border-radius: 8px;
          padding: 0.3rem 0.8rem;
          cursor: pointer;
          line-height: 1.5;
          font-family: 'Roboto', sans-serif;
          font-size: 0.9rem;

          &:hover {
            opacity: 0.8;
          }

          &.active {
            color: white;
            border-color: root-var.$secondary;
            background-color: root-var.$secondary !important;
          }

          & > i-feather {
            margin-right: 0.2rem;
            width: 18px;
            height: 18px;
          }

          & > p {
            margin: 0;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.5rem */
            letter-spacing: 0.02rem;
            max-width: 260px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    `,
  ],
})
export class SquaredChipComponent {
  @Input()
  public icon?: FeatherIconType;

  @Input()
  public label!: string;

  @Input()
  public backgroundColor?: string;

  @Input()
  public fontColor?: string;
}
