<section class="auth-img-wrapper">
  <img src="/assets/image/auth-img.svg" alt="Logo" loading="lazy" />
</section>

@defer {
  <ng-container *ngIf="externalApplicationViewModel.vm$ | async as vm">
    <section class="external-application-container">
      @if ((!vm.jobDetails && !vm.jobDetailsList) || (loadingViewModel.isLoading$ | async)) {
        <wh-s-spinner />
      } @else {
        <div class="header">
          <img src="/assets/image/workerhero_logo.png" alt="Logo" loading="lazy" />

          <wh-language-switch-new
            [languages]="(languageSwitchService.vm$ | async)?.languagesNew ?? []"
            (languageClicked)="switchLanguage($event)"
          />
        </div>
        @if (vm.publicJobFound) {
          <div class="details">
            <h3 class="wh-header-lg">{{ vm.jobDetails?.titleTextVariants | textVariant }}</h3>

            <wh-s-profile-detail-video
              *ngIf="vm.jobDetails?.jobBranding?.videoLink"
              [hasTitle]="false"
              [videoUrl]="videoLinkTransform(vm.jobDetails?.jobBranding?.videoLink ?? '')"
            />

            @if (vm.jobDescriptionFlagValue === 'short-description') {
              <wh-s-profile-detail-about [about]="vm.jobDetails?.jobBranding?.shortDescription | textVariant" />
            } @else {
              <wh-s-profile-detail-about [about]="vm.jobDetails?.jobBranding?.description | textVariant" />
            }
          </div>

          <div class="actions">
            <wh-s-button
              class="apply"
              [elevated]="false"
              [disabled]="loadingViewModel.isLoading$ | async"
              [label]="'domain.jobs.feature.job-details.actions.apply-label' | transloco"
              (clickAction)="processApplication()"
            />

            <wh-s-button
              class="discard"
              [label]="'domain.jobs.feature.job-details.actions.cancel-label' | transloco"
              [elevated]="false"
              [disabled]="loadingViewModel.isLoading$ | async"
              variant="zero"
              (clickAction)="externalApplicationViewModel.toggleAbortDialog()"
            />
          </div>
        }
        @if (!vm.publicJobFound) {
          <section class="header">
            <h2 class="wh-header-lg title">
              {{ 'domain.jobs.feature.external-application.job-no-exist.title' | transloco }}
            </h2>
          </section>
          <section class="details">
            <wh-s-profile-detail-job-list
              *ngIf="vm.profileDetailJobViewList?.length"
              [title]="'domain.jobs.feature.external-application.job-no-exist.other-jobs-list-title' | transloco"
              [company]="vm.jobDetails?.company?.name ?? ''"
              [jobs]="vm.profileDetailJobViewList ?? []"
              (ctaClicked)="externalApplicationViewModel.openSuggestedExternalJobDetails($event)"
            />
          </section>
          <section class="auth">
            <h5 class="wh-header-h5">
              {{ 'domain.jobs.feature.external-application.job-no-exist.auth.header' | transloco }}
            </h5>
            <p>{{ 'domain.jobs.feature.external-application.job-no-exist.auth.description' | transloco }}</p>
            <div class="auth-actions">
              <wh-s-button
                [label]="'domain.jobs.feature.external-application.job-no-exist.auth.register-btn-label' | transloco"
                (clickAction)="externalApplicationViewModel.goToRegister()"
              />
              <p>
                {{ 'domain.jobs.feature.external-application.job-no-exist.auth.login-desc' | transloco
                }}<a (click)="externalApplicationViewModel.goToLogin()">{{
                  'domain.jobs.feature.external-application.job-no-exist.auth.login-action' | transloco
                }}</a>
              </p>
            </div>
          </section>
        }
      }
    </section>

    <wh-s-app-abort-application
      *ngIf="vm.abortDialogOpened"
      [isOpened]="vm.abortDialogOpened"
      (cancelAction)="externalApplicationViewModel.toggleAbortDialog()"
      (abortApplication)="externalApplicationViewModel.abortApplication($event)"
    />
  </ng-container>
} @loading {
  <wh-s-spinner />
}
