import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicantVerificationViewModel } from '@web/web/applicant/core/auth/data-access';

@Component({
  selector: 'wh-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class VerifyAccountComponent implements OnInit {
  public get applicantId(): string {
    return this.activatedRoute.snapshot.params['userId'];
  }

  constructor(
    public readonly verificationViewModel: ApplicantVerificationViewModel,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.verificationViewModel.verifyUser(this.applicantId);
  }
}
