import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { ApplicantTrackingSystemApi, JobApi, JobApplicationApi, PosthogApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicantAuthViewModel } from '@web/web/applicant/core/auth/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AimwelService } from '@web/web/shared/data-access/aimwel';
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { JobrapidoService } from '@web/web/shared/data-access/jobrapido';
import { JoobleService } from '@web/web/shared/data-access/jooble';
import { PosthogService } from 'posthog';
import { Observable, catchError, of, switchMap, take, tap } from 'rxjs';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';

export interface ExternalApplicationState {
  jobId: string;
  jobDetails?: JobApi.JobDetails;
  jobDetailsList?: JobApi.JobDetails[];
  profileDetailJobViewList?: JobApi.ProfileDetailJobView[];
  abortDialogOpened?: boolean;
  abortApplication?: JobApplicationApi.AbortApplication;
  zapier?: boolean;
  isAssessmentPassed?: boolean;
  publicJobFound: boolean;
  jobDescriptionFlagActive: boolean;
  jobDescriptionFlagValue: JobDescriptionFlagValue;
}

export enum JobDescriptionFlagValue {
  SHORT_DESCRIPTION = 'short-description',
  LONG_DESCRIPTION = 'long-description',
}

@Injectable({
  providedIn: 'root',
})
export class ExternalApplicationViewModel extends ComponentStore<ExternalApplicationState> {
  public readonly vm$: Observable<ExternalApplicationState> = this.select(state => ({
    jobId: state.jobId,
    jobDetails: state.jobDetails,
    jobDetailsList: state.jobDetailsList,
    profileDetailJobViewList: state.profileDetailJobViewList,
    abortDialogOpened: state.abortDialogOpened,
    abortApplication: state.abortApplication,
    zapier: state.zapier,
    isAssessmentPassed: state.isAssessmentPassed,
    publicJobFound: state.publicJobFound,
    jobDescriptionFlagActive: state.jobDescriptionFlagActive,
    jobDescriptionFlagValue: state.jobDescriptionFlagValue,
  }));

  constructor(
    private readonly router: Router,
    public readonly alertService: AlertService,
    public readonly jobApiService: JobApiService,
    public readonly jobApplicationApiService: JobApplicationApiService,
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    private readonly utmLocalStorageService: UtmLocalStorageService,
    private readonly jobrapidoService: JobrapidoService,
    private readonly joobleService: JoobleService,
    private readonly aimwelService: AimwelService,
    private readonly applicantPosthogService: PosthogService,
  ) {
    super({
      jobId: '',
      publicJobFound: false,
      jobDescriptionFlagActive: true,
      jobDescriptionFlagValue: JobDescriptionFlagValue.SHORT_DESCRIPTION,
    });
  }

  public setJobId(jobId: string): void {
    this.patchState({ jobId });
  }

  public setZapier(zapier: boolean): void {
    this.patchState({ zapier });
  }

  public setIsAssessmentPassed(isAssessmentPassed: boolean | undefined): void {
    this.patchState({ isAssessmentPassed: isAssessmentPassed });
  }

  public setJobDescriptionFlagValue(jobDescriptionFlagValue: JobDescriptionFlagValue): void {
    this.patchState({ jobDescriptionFlagValue });
  }

  public getJobDetailsOrSuggestions(): void {
    const id = this.get().jobId;
    const showShortDescription = this.get().jobDescriptionFlagValue === JobDescriptionFlagValue.SHORT_DESCRIPTION;

    this.jobApiService
      .getJobDetailsOrSuggestionsByIdForExternalApplication(id, showShortDescription)
      .pipe(
        take(1),
        tap(jobDetailsList => {
          this.patchState({ jobDetailsList });
          if (jobDetailsList && jobDetailsList[0].id === id) {
            this.patchState({ publicJobFound: true });
            this.patchState({ jobDetails: jobDetailsList[0] });
          } else {
            this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.EXTERNAL_APPLICATION_JOB_UNAVAILABLE);
            const profileDetailJobViewList: JobApi.ProfileDetailJobView[] = jobDetailsList.map(jobDetails => {
              return this.mapJobDetailsToProfileDetailJobView(jobDetails);
            });
            this.patchState({ profileDetailJobViewList: profileDetailJobViewList });
          }
        }),
      )
      .subscribe();
  }

  public createApplication(redirectToLogin = false): void {
    const jobDetails: JobApplicationApi.JobApplication = {
      jobId: String(this.get().jobId),
      source: ApplicantTrackingSystemApi.Source.EXTERNAL,
      utm: this.utmLocalStorageService.getUtmData(),
      abort: this.get().abortApplication ?? undefined,
      zapier: this.get().zapier,
      isAssessmentPassed: this.get().isAssessmentPassed,
    };

    this.applicantAuthViewModel.isAuthenticated$
      .pipe(
        take(1),
        switchMap(isAuthenticated => {
          const registerData = localStorage.getItem('create_account_data_key');
          if (isAuthenticated && !registerData?.length) {
            return this.jobApplicationApiService.create(jobDetails).pipe(
              take(1),
              tap(jobApplication => {
                this.jobrapidoService.sendJobrapidoApplicationCompleteEvent();
                this.joobleService.sendJoobleApplicationCompleteEvent();
                this.aimwelService.sendAimwelApplicationCompleteEvent();

                if (jobApplication && jobApplication.currentStep?.assessment) {
                  this.router.navigate([`/jobs/assessment/${jobApplication.currentStep?.assessment.id}`]);
                } else {
                  if (jobApplication) {
                    this.alertService.success('You have successfully applied for the job.');
                  }
                  this.applicantAuthViewModel.removeExternalApplicationData();

                  this.router.navigate(['/jobs']);
                }

                this.utmLocalStorageService.removeUtmData();
              }),
              catchError(error => {
                if (error.error.statusCode === 409) {
                  this.alertService.info('You have already applied for this job.');

                  this.applicantAuthViewModel.removeExternalApplicationData();

                  this.router.navigate([`/`]);

                  return of({});
                } else {
                  return of({});
                }
              }),
            );
          } else {
            this.applicantAuthViewModel.setExternalApplicationData(jobDetails);

            if (redirectToLogin) {
              return this.router.navigate([`/auth/login`], { queryParamsHandling: 'merge' });
            }

            return this.router.navigate([`/auth/register`], { queryParamsHandling: 'merge' });
          }
        }),
      )
      .subscribe();
  }

  public toggleAbortDialog(): void {
    const abortDialogOpened = this.get().abortDialogOpened;

    this.patchState({ abortDialogOpened: !abortDialogOpened });
  }

  public abortApplication(abortApplication: JobApplicationApi.AbortApplication): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.EXTERNAL_APPLICATION_NOT_INTERESTED, {
      reason: abortApplication.applicantNoFitReason,
      text: abortApplication.noHireText,
    });

    this.patchState({ abortApplication });
    this.toggleAbortDialog();
    this.router.navigate([`/auth/register`], { queryParamsHandling: 'merge', queryParams: { jobId: undefined } });
  }

  public mapJobDetailsToProfileDetailJobView(jobDetails: JobApi.JobDetails): JobApi.ProfileDetailJobView {
    return {
      id: jobDetails.id,
      title: jobDetails.titleTextVariants,
      compensations: jobDetails.compensations,
      jobType: jobDetails.jobType.textVariants,
      address: `${jobDetails.address.postalCode} ${jobDetails.address.city}`,
      employmentTypes: jobDetails.employmentTypes,
    };
  }

  public openSuggestedExternalJobDetails(id: string): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.EXTERNAL_APPLICATION_OPEN_SUGGESTED_JOB, {
      jobId: id,
    });

    this.router.navigate([`/external-application/${id}`]).then(() => {
      this.patchState({ jobId: id });
      this.setJobId(id);
      this.getJobDetailsOrSuggestions();
    });
  }

  public goToRegister(): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.EXTERNAL_APPLICATION_NAVIGATE_REGISTRATION);

    this.router.navigate(['/register']);
  }

  public goToLogin(): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.EXTERNAL_APPLICATION_NAVIGATE_LOGIN);

    this.router.navigate(['/auth']);
  }
}
