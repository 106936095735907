import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { FeatherModule } from 'angular-feather';
import { ProfileSettingsDropdownComponent } from 'web/shared/ui/profile-settings-dropdown';

@Component({
  selector: 'wh-s-avatar-wrapper',
  template: `
    <figure class="avatar" (click)="profileClick.emit()">
      <div class="wrapper">
        <img
          *ngIf="avatarSrc; else userIcon"
          [src]="avatarSrc"
          alt="{{ 'shared.ui.avatar-wrapper.alt-image-message' | transloco }}"
        />

        <ng-template #userIcon>
          <i-feather class="no-image-icon" name="user"></i-feather>
        </ng-template>
      </div>

      <figcaption>{{ username }}</figcaption>

      @if (hasProfileSettingsDropdown) {
        <i-feather [name]="showProfileDropdown ? 'chevron-up' : 'chevron-down'"></i-feather>
      }

      @if (hasProfileSettingsDropdown && showProfileDropdown) {
        <wh-s-profile-settings-dropdown />
      }
    </figure>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;

      :host {
        cursor: pointer;
        padding: 0.4rem 1.3rem;
        border-radius: 10px;

        &:hover {
          background-color: var(--side-nav-item-hover-background-color);
          transition: background-color 100ms ease-in-out;
        }

        & > .avatar {
          margin: 0;
          height: 100%;
          cursor: pointer;

          @include mx.flex-center-x();

          @include mx.df-screen(tablet-landscape) {
            margin: 0 2rem 0 0;
          }

          @include mx.df-screen(mobile) {
            /*display: none;*/
            margin: 0;
          }

          @include mx.df-screen(mobile-small) {
            margin-right: 0.5rem;
          }

          & > .wrapper {
            @include mx.flex-center-x();
            width: 2.2rem;
            height: 2.2rem;

            @include mx.df-screen(tablet-landscape) {
              margin-left: auto;
            }

            & > img {
              border-radius: 50%;
              border: 1px solid gainsboro;
              cursor: pointer;
              width: 100%;
              height: 100%;
            }

            & .no-image-icon {
              width: 100%;
              padding: 0.2rem;
              border-radius: 50%;
              border: 1px solid gainsboro;
              cursor: pointer;
              box-sizing: unset;
            }
          }

          & > figcaption {
            letter-spacing: 0.02em;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 1rem;
            margin-left: 0.5rem;
            display: inline-block;
            text-wrap: nowrap;

            @include mx.df-screen(tablet-portrait) {
              display: none;
            }
          }
        }
      }

      :host(.active) {
        background-color: var(--primary);
      }
    `,
  ],
  imports: [CommonModule, FeatherModule, RouterLink, WebCoreI18nModule, ProfileSettingsDropdownComponent],
})
export class AvatarWrapperComponent {
  @Input()
  public avatarSrc: string;

  @Input()
  public username: string;

  @Input()
  public hasProfileSettingsDropdown = false;

  public showProfileDropdown = false;

  @Output()
  public profileClick = new EventEmitter<void>();

  @HostListener('click')
  public onClick(): void {
    this.showProfileDropdown = !this.showProfileDropdown;
  }
}
