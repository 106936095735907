import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ComponentStore } from '@ngrx/component-store';
import {
  ApplicantTrackingSystemApi,
  GoogleJobPostingApi,
  JobApi,
  JobApplicationApi,
  PosthogApi,
  UtmApi,
} from '@web/shared/data-access/model';
//  TODO: Fix lib relationship
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicantAuthViewModel } from '@web/web/applicant/core/auth/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { PosthogService } from 'posthog';
import { Observable, tap } from 'rxjs';
import { takeOne } from 'web/shared/util/rxjs-operator';

interface JobDetailsState {
  jobId: string;
  jobDetailPreview: JobApi.JobDetailPreview | null;
  successPopUpOpened: boolean;
  isJobDetailLoading: boolean;
  isApplyJobLoading: boolean;
  utmData?: UtmApi.UtmData;
}

@Injectable({
  providedIn: 'root',
})
export class JobDetailsNewViewModel extends ComponentStore<JobDetailsState> {
  public readonly vm$: Observable<JobDetailsState> = this.select(state => ({
    jobId: state.jobId,
    jobDetailPreview: state.jobDetailPreview,
    successPopUpOpened: state.successPopUpOpened,
    isJobDetailLoading: state.isJobDetailLoading,
    isApplyJobLoading: state.isApplyJobLoading,
    utmData: state.utmData,
  }));

  constructor(
    public readonly jobApiService: JobApiService,
    public readonly jobApplicationApiService: JobApplicationApiService,
    public readonly alertService: AlertService,
    private readonly router: Router,
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    private readonly translocoService: TranslocoService,
    private readonly applicantPosthogService: PosthogService,
  ) {
    super({
      jobId: '',
      successPopUpOpened: false,
      isJobDetailLoading: false,
      isApplyJobLoading: false,
      jobDetailPreview: null,
      utmData: undefined,
    });
  }

  public setUtmData(utmData: UtmApi.UtmData): void {
    this.patchState({ utmData });
  }

  public fetchJobDetails(jobId: string): void {
    this.patchState({ jobId, isJobDetailLoading: true });
    const applicantId = this.applicantAuthViewModel.getLoginData()?.id ?? null;

    this.jobApiService
      .fetchJobDetailPreview(jobId, applicantId)
      .pipe(
        takeOne(),
        tap(jobDetailPreview => {
          this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.JOB_VIEW, { jobId });
          this.patchState({ jobDetailPreview, isJobDetailLoading: false });
        }),
      )
      .subscribe();
  }

  public fetchMetaDataAndJobPostingData(jobId: string): Observable<GoogleJobPostingApi.MetaDataWrapper> {
    return this.jobApiService.fetchMetaDataAndJobPostingData(jobId);
  }

  public createApplication(): void {
    this.patchState({ isApplyJobLoading: true });

    const jobDetails: JobApplicationApi.JobApplication = {
      jobId: String(this.get().jobId),
      source: ApplicantTrackingSystemApi.Source.INTERNAL,
    };

    let utmData = this.get().utmData;

    if (utmData && !Object.values(utmData).find(val => val.length > 0)) {
      utmData = undefined;
    }

    if (utmData) jobDetails.utm = utmData;

    this.jobApplicationApiService
      .create(jobDetails)
      .pipe(
        takeOne(),
        tap(jobApplication => {
          if (jobApplication.currentStep?.assessment) {
            this.router.navigate([`/jobs/assessment/${jobApplication.currentStep?.assessment.id}`]);
          } else {
            this.router.navigate(['../jobs']);
            this.alertService.success(this.translocoService.translate('domain.jobs.feature.apply.success-message'));
          }

          this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.JOB_APPLICATION, {
            jobId: jobDetails.jobId,
          });

          this.patchState({ isApplyJobLoading: false });
        }),
      )
      .subscribe();
  }

  public openCompanyDetail(): void {
    const { jobDetailPreview } = this.get();

    if (!jobDetailPreview) {
      return;
    }

    this.router.navigate([`companies/${jobDetailPreview.company.id}`]);
  }

  public destroy(): void {
    this.patchState({
      jobId: '',
      successPopUpOpened: false,
      isJobDetailLoading: false,
      isApplyJobLoading: false,
      jobDetailPreview: null,
    });
  }

  public toggleSuccessPopUp(): void {
    const successPopUpOpened = this.get().successPopUpOpened;

    this.patchState({ successPopUpOpened: !successPopUpOpened });
  }
}
