import { Route } from '@angular/router';
import { ApplicantLoggedInGuard } from '@web/web/applicant/core/auth/data-access';
import { VerifyAccountComponent } from '@web/web/applicant/core/auth/feature/verify-account';
import { ExternalApplicationComponent } from '@web/web/applicant/domain/job/feature/external-application';
import { NotFoundComponent } from 'web/shared/ui/not-found';
import { AppComponent } from './app.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: async () => (await import('@web/web/applicant/shell')).WebApplicantShellModule,
      },
      /*
      {
        path: 'public-profile/:id',
        loadChildren: async () => (await import('@web/web/applicant/domain/profile/feature/public')).PublicModule,
      },
      */
      {
        path: 'external-application/:jobId',
        component: ExternalApplicationComponent,
      },
      {
        path: 'verify/:userId',
        component: VerifyAccountComponent,
      },
      {
        path: 'auth',
        canActivate: [ApplicantLoggedInGuard],
        loadChildren: async () =>
          (await import('@web/web/applicant/core/auth/feature/shell')).WebApplicantCoreAuthFeatureShellModule,
      },
      { path: 'login', redirectTo: 'auth/login' },
      { path: 'v2/signin', redirectTo: 'auth/login' },
      { path: 'v2/signup', redirectTo: 'auth/register' },
      { path: 'sign', redirectTo: 'auth/login' },
      { path: 'register', redirectTo: 'auth/register' },
      { path: 'sign/register', redirectTo: 'auth/register' },
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '/404' },
    ],
  },
];
