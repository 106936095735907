import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JobApi } from '@web/shared/data-access/model';
import { ProfileDetailJobListItemComponent } from '@web/web/shared/ui/profile-detail-job-list-item';
import { TextVariantPipe } from '@web/web/shared/util/pipes';

@Component({
  selector: 'wh-s-profile-detail-job-list',
  imports: [CommonModule, ProfileDetailJobListItemComponent, TextVariantPipe],
  template: `
    <div class="title-wrapper">
      <h4 class="wh-header-h4">{{ title }} {{ company }}</h4>
    </div>

    <section class="other-jobs">
      <wh-s-profile-detail-job-list-item
        *ngFor="let job of jobs"
        [title]="job.title | textVariant"
        [location]="job.address"
        [employmentTypes]="job.employmentTypes"
        [compensations]="job.compensations"
        (ctaClick)="ctaClicked.emit(job.id)"
      ></wh-s-profile-detail-job-list-item>
    </section>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;

      :host {
        display: block;
        width: 100%;

        & > .title-wrapper {
          margin-top: 2rem;

          max-width: 90%;
          overflow: hidden;
          word-break: break-word;

          & > h4 {
            @include mx.df-screen(mobile) {
              font-size: 40px;
            }
          }
        }

        & > .other-jobs {
          display: flex;
          overflow-x: auto;
          margin-bottom: 1rem;

          & > wh-s-profile-detail-job-list-item {
            margin-right: 1.5rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    `,
  ],
})
export class ProfileDetailJobListComponent {
  @Input({
    required: true,
  })
  public title: string;

  @Input({
    required: true,
  })
  public company: string;

  @Input({
    required: true,
  })
  public jobs: JobApi.ProfileDetailJobView[];

  @Output()
  public ctaClicked = new EventEmitter<string>();
}
