/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PaginateWithSearchAndFilters } from '@web/shared/util/paginate';
import { ApplicantApi } from './applicant-api.namespace';
import { ApplicantApprenticeshipApi } from './applicant-apprenticeship-api.namespace';
import { ApplicantEducationApi } from './applicant-education-api.namespace';
import { ApplicantLanguageApi } from './applicant-language-api.namespace';
import { ApplicantWorkExperienceApi } from './applicant-work-experience-api.namespace';
import { CertificateApi } from './certificate-api.namespace';
import { DriverLicenseApi } from './driver-license-api.namespace';
import { NoteApi } from './note-api.namespace';
import { RecruitmentProcessApi } from './recruitment-process-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';
import { TranslocoApi } from './transloco-api.namespace';

export namespace ApplicantTrackingSystemApiNew {
  export enum Tab {
    PROFILE = 'PROFILE',
    NOTE = 'NOTE',
    RECRUITING_HISTORY = 'RECRUITING_HISTORY',
    DOCUMENTS = 'DOCUMENTS',
    AI_EVALUATION = 'AI_EVALUATION',
  }

  export interface JobApplicationListItem {
    id: string;
    applicant: string;
    applicantEmail: string;
    applicantId: string;
    jobName: TextVariantApi.TextVariant[];
    jobCity: string;
    companyName: string;
    currentStep: string;
    visibleSince: Date;
    createdAt: Date;
    isPotentialPick: boolean;
    isPick: boolean;
    isHidden: boolean;
    canChangePhase: boolean;
  }

  export interface JobApplicationListItemSimple {
    id: string;
    applicant: string;
    applicantId: string;
    applicantAvatarUrl: string;
    jobName: TextVariantApi.TextVariant[];
    jobCity: string;
    currentStep: string;
    visibleSince: Date;
    appliedOn: Date;
    isPotentialPick: boolean;
    isPick: boolean;
    isHidden: boolean;
    isNewApplication: boolean;
  }

  export interface PhaseInfo {
    jobApplicationId: string;
    availableSteps: RecruitmentProcessApi.RecruitmentStepSimple[];
  }

  export interface ApplicationPhase {
    applicant: string;
    jobApplicationId: string;
    title: TextVariantApi.TextVariant[];
    steps: RecruitmentProcessApi.RecruitmentStepSimple[];
    isPick: boolean;
    isPotentialPick: boolean;
  }

  export interface ChangePhase {
    jobApplicationId: string;
    newStepId: string;
    applicantNoFitReason?: RecruitmentProcessApi.ApplicantNoHireReason;
    noHireReason?: RecruitmentProcessApi.NoHireReason;
    noHireText?: string;
    note?: NoteApi.CreateNoteSimple;
    interviewMessage?: string;
    skipCommunication: boolean;
  }

  export interface RequestChangePhase {
    jobApplicationId: string;
    text: string;
  }

  export interface SearchData {
    page: number;
    pageSize: number;
    searchTerm: string;
    filters: ListFilter;
  }

  export interface ListFilter {
    //  TODO: Use type once JobATS interface has been nuked to the ground
    jobs: any[];
    cities: string[];
    applicationTypes: string[];
    recruitmentProcessSteps: RecruitmentProcessApi.RecruitmentStepSimple[];
    timeInStepLessThanDays: number | null;
    timeInStepGreaterThanDays: number | null;
    order: string | null;
  }

  export interface ListFilterView {
    //  TODO: Use type once JobATS interface has been nuked to the ground
    jobs: FilterItem<any>[];
    cities: FilterItem<string>[];
    applicationTypes: FilterItemTransloco<string>[];
    recruitmentProcessSteps: FilterItemTransloco<RecruitmentProcessApi.RecruitmentStepSimple>[];
    timeSpentInStep: FilterItemTransloco<{
      timeInStepLessThanDays: number | null;
      timeInStepGreaterThanDays: number | null;
    }>[];
    order: FilterItemTransloco<string>[];
  }

  export interface FilterItem<T> {
    payload: T;
    isActive: boolean;
  }

  export interface FilterItemTransloco<T> {
    payload: T;
    textTransloco: string;
    isActive: boolean;
  }

  export type SearchFilters = PaginateWithSearchAndFilters<ListFilter>;

  export enum ApplicationSource {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
  }

  export interface ApplicantProfile {
    id: string;
    avatarUrl: string;
    address: string;
    firstName: string;
    lastName: string;
    birthDate: Date;
    nationality: TranslocoApi.Nationality;
    communicationLanguage: string;
    isAccountActive: boolean;
    email: string;
    phone: string;
    jobApplicationSource: ApplicationSource;
    isJobApplicationHidden: boolean;
    isPick: boolean;
    isPotentialPick: boolean;
    currentStep: string;
    canChangePhase: boolean;
  }

  export interface ApplicantProfileSimple {
    id: string;
    avatarUrl: string;
    address: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    birthDate: Date;
    nationality: TranslocoApi.Nationality;
    isAllDataVisible: boolean;
  }

  export interface ApplicantProfessionalProfile {
    workExperiences: ApplicantWorkExperienceApi.WorkExperience[];
    educations: ApplicantEducationApi.Education[];
    apprenticeships: ApplicantApprenticeshipApi.Apprenticeship[];
    licenses: DriverLicenseApi.DriverLicenseApplicant[];
    certificates: CertificateApi.Certificate[];
    documents: ApplicantApi.DocumentItem[];
    language: ApplicantLanguageApi.Language[];
  }
}
