import { CommonModule } from '@angular/common';
import { Component, OnInit, input } from '@angular/core';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';

@Component({
  selector: 'wh-utm-data',
  imports: [CommonModule],
  template: '',
  styleUrls: [],
})
export class UtmDataComponent implements OnInit {
  public utm_campaign = input<string | undefined>();
  public utm_source = input<string | undefined>();
  public utm_content = input<string | undefined>();
  public utm_medium = input<string | undefined>();
  public utm_term = input<string | undefined>();

  constructor(protected readonly utmLocalStorageService: UtmLocalStorageService) {}

  public ngOnInit(): void {
    this.utmLocalStorageService.updateUtmProperties(
      this.utm_campaign(),
      this.utm_source(),
      this.utm_content(),
      this.utm_medium(),
      this.utm_term(),
    );
  }
}
