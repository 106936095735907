export namespace PosthogApi {
  export enum PostHogEvent {
    JOB_VIEW = 'Job view',
    COMPANY_VIEW = 'Company view',
    JOB_APPLICATION = 'Job application',
    JOB_SEARCH = 'Job_search',
    CREATE_LANG_DIALOG_OPENED = 'create_language_dialog_opened',
    CREATE_LANG_DIALOG_SUBMITTED = 'create_language_dialog_submitted',
    CREATE_LANG_DIALOG_CANCELLED = 'create_language_dialog_cancelled',
    EDIT_LANG_DIALOG_OPENED = 'edit_language_dialog_opened',
    EDIT_LANG_DIALOG_SUBMITTED = 'edit_language_dialog_submitted',
    EDIT_LANG_DIALOG_CANCELLED = 'edit_language_dialog_cancelled',
    CREATE_EDUCATION_DIALOG_OPENED = 'create_education_dialog_opened',
    CREATE_EDUCATION_DIALOG_SUBMITTED = 'create_education_dialog_submitted',
    CREATE_EDUCATION_DIALOG_CANCELLED = 'create_education_dialog_canceled',
    EDIT_EDUCATION_DIALOG_OPENED = 'edit_education_dialog_opened',
    EDIT_EDUCATION_DIALOG_SUBMITTED = 'edit_education_dialog_submitted',
    EDIT_EDUCATION_DIALOG_CANCELLED = 'edit_education_dialog_canceled',
    CREATE_APPRENTICESHIP_DIALOG_OPENED = 'create_apprenticeship_dialog_opened',
    CREATE_APPRENTICESHIP_DIALOG_SUBMITTED = 'create_apprenticeship_dialog_submitted',
    CREATE_APPRENTICESHIP_DIALOG_CANCELLED = 'create_apprenticeship_dialog_canceled',
    EDIT_APPRENTICESHIP_DIALOG_OPENED = 'edit_apprenticeship_dialog_opened',
    EDIT_APPRENTICESHIP_DIALOG_SUBMITTED = 'edit_apprenticeship_dialog_submitted',
    EDIT_APPRENTICESHIP_DIALOG_CANCELLED = 'edit_apprenticeship_dialog_canceled',
    CREATE_LICENSE_DIALOG_OPENED = 'create_license_dialog_opened',
    CREATE_LICENSE_DIALOG_SUBMITTED = 'create_license_dialog_submitted',
    CREATE_LICENSE_DIALOG_CANCELLED = 'create_license_dialog_canceled',
    EDIT_LICENSE_DIALOG_OPENED = 'edit_license_dialog_opened',
    EDIT_LICENSE_DIALOG_SUBMITTED = 'edit_license_dialog_submitted',
    EDIT_LICENSE_DIALOG_CANCELLED = 'edit_license_dialog_canceled',
    CREATE_WORK_EXPERIENCE_OPENED = 'create_work_experience_dialog_opened',
    CREATE_WORK_EXPERIENCE_SUBMITTED = 'create_work_experience_dialog_submitted',
    CREATE_WORK_EXPERIENCE_CANCELLED = 'create_work_experience_dialog_canceled',
    EDIT_WORK_EXPERIENCE_OPENED = 'edit_work_experience_dialog_opened',
    EDIT_WORK_EXPERIENCE_SUBMITTED = 'edit_work_experience_dialog_submitted',
    EDIT_WORK_EXPERIENCE_CANCELLED = 'edit_work_experience_dialog_canceled',
    EDIT_PERSONAL_INFORMATION_OPENED = 'edit_personal_information_dialog_opened',
    EDIT_PERSONAL_INFORMATION_SUBMITTED = 'edit_personal_information_dialog_submitted',
    EDIT_PERSONAL_INFORMATION_CANCELLED = 'edit_personal_information_dialog_canceled',
    EXTERNAL_JOB_VIEW = 'external job view',
    START_APPLICATION = 'start application',
    LOGIN = 'login',
    APPLICANT_REGISTRATION_EXTERNAL = 'applicant registration - external application',
    SUBMIT_ASSESSMENT = 'submit assessment',
    REGISTRATION_STEP_CHANGE = 'registration step change',
    REGISTRATION_SET_APPRENTICESHIP = 'applicant registration - set apprenticeship',
    REGISTRATION_SET_APPRENTICESHIP_JOB_TYPE = 'applicant registration - set apprenticeship job type',
    REGISTRATION_SET_WORK_EXPERIENCE = 'applicant registration - set work experience',
    REGISTRATION_SET_WORK_EXPERIENCE_JOB_TYPE = 'applicant registration - set work experience job type',
    REGISTRATION_SET_DRIVER_LICENSE = 'applicant registration - set driver licenses',
    REGISTRATION_SET_ENGLISH_LEVEL = 'applicant registration - set english level',
    REGISTRATION_SET_GERMAN_LEVEL = 'applicant registration - set german level',
    REGISTRATION_SET_ADDRESS = 'applicant registration - set address',
    REGISTRATION_SET_PHONE_NUMBER = 'applicant registration - set phone number',
    REGISTRATION_PHONE_NUMBER_IN_USE = 'applicant registration - phone number already in use',
    REGISTRATION_PHONE_NUMBER_ERR = 'applicant registration - other phone number error',
    REGISTRATION_CREATE_ACCOUNT = 'applicant registration - create account',
    REGISTRATION_SET_OTP = 'applicant registration - set otp',
    REGISTRATION_FINISH = 'registration finish',
    REGISTRATION_GOOGLE = 'applicant registration - google register',
    REGISTRATION_GOOGLE_SUCCESS = 'applicant registration - google success',
    REGISTRATION_GOOGLE_ERROR = 'applicant registration - google error',
    REGISTRATION_GOOGLE_ACC_IN_USE = 'applicant registration - google account already in use',
    REGISTRATION_GOOGLE_INIT = 'applicant registration - google init',
    REGISTRATION_GOOGLE_PERMISSION_DENIED = 'applicant registration - google permission denied',
    REGISTRATION_EMAIL_IN_USE_ERROR = 'applicant registration - email already in use',
    REGISTRATION_OTHER_ERROR = 'applicant registration - other error',
    EXTERNAL_APPLICATION_NOT_INTERESTED = 'external application - not interested',
    EXTERNAL_APPLICATION_OPEN_SUGGESTED_JOB = 'external application - open suggested job',
    EXTERNAL_APPLICATION_NAVIGATE_REGISTRATION = 'external application - navigate to registration',
    EXTERNAL_APPLICATION_NAVIGATE_LOGIN = 'external application - navigate to login',
    EXTERNAL_APPLICATION_JOB_UNAVAILABLE = 'external application - job unavailable ',
    APPLICATION_DETAIL_VIEW = 'Application details view',
    APPLICATION_ABORT = 'Abort application',
  }
}
