import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { AssessmentApi, PosthogApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicantAuthViewModel } from '@web/web/applicant/core/auth/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AlertService } from '@web/web/shared/data-access/alert';
import { AssessmentApiService } from '@web/web/shared/data-access/api';
import { PosthogService } from 'posthog';
import { Observable, take, tap } from 'rxjs';

export interface ApplicantAssessmentState {
  assessmentId: string;
  applicantAssessment: AssessmentApi.ApplicantAssessment;
  assessmentDetails?: AssessmentApi.AssessmentDetails;
  isFormValid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicantAssessmentViewModel extends ComponentStore<ApplicantAssessmentState> {
  public readonly vm$: Observable<ApplicantAssessmentState> = this.select(state => ({
    assessmentId: state.assessmentId,
    assessmentDetails: state.assessmentDetails,
    applicantAssessment: state.applicantAssessment,
    isFormValid: this.isFormValid(state.applicantAssessment, state.assessmentDetails),
  }));

  constructor(
    private readonly router: Router,
    public readonly alertService: AlertService,
    public readonly assessmentApiService: AssessmentApiService,
    public readonly applicantAuthViewModel: ApplicantAuthViewModel,
    public readonly applicantPosthogService: PosthogService,
  ) {
    super({
      assessmentId: '',
      applicantAssessment: { assessmentId: '', answers: [] },
      isFormValid: false,
    });
  }

  public setAssessmentId(assessmentId: string): void {
    this.patchState({ assessmentId });
    this.patchState({ applicantAssessment: { assessmentId, answers: [] } });
  }

  public getAssessmentDetails(): void {
    const id = this.get().assessmentId;
    this.assessmentApiService
      .getAssessmentDetailsById(id)
      .pipe(
        take(1),
        tap((assessmentDetails: AssessmentApi.AssessmentDetails) => {
          this.patchState({ assessmentDetails });
        }),
      )
      .subscribe();
  }

  public setAnswer(answer: AssessmentApi.ApplicantAssessmentAnswer): void {
    const applicantAssessment = this.get().applicantAssessment;

    if (!applicantAssessment.answers.map(a => a.questionId).includes(answer.questionId))
      applicantAssessment.answers.push(answer);
    else {
      const answerIndex = applicantAssessment.answers.map(a => a.questionId).indexOf(answer.questionId);
      applicantAssessment.answers[answerIndex] = answer;
    }

    this.patchState({ applicantAssessment });
  }

  public submitApplicantsAssessment(registerFlag?: boolean): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.SUBMIT_ASSESSMENT);

    const answerIds: string[] = [];
    this.get().applicantAssessment.answers.forEach(a => answerIds.push(...a.answerIds));

    this.assessmentApiService
      .submitApplicantsAssessment(this.get().applicantAssessment.assessmentId, answerIds)
      .pipe(
        take(1),
        tap(() => {
          setTimeout(() => {
            const externalApplicationData = localStorage.getItem(
              this.applicantAuthViewModel.externalApplicationDataKey,
            );

            if (externalApplicationData) {
              this.alertService.success('You have successfully applied for the job.');
              this.applicantAuthViewModel.removeExternalApplicationData();
            }

            this.alertService.success('Assessment successfully completed.');

            if (!registerFlag) {
              this.router.navigate([`/jobs`]);
            }
          }, 1500);
        }),
      )
      .subscribe();
  }

  private isFormValid(
    applicantAssessment: AssessmentApi.ApplicantAssessment,
    assessmentDetails: AssessmentApi.AssessmentDetails | undefined,
  ): boolean {
    const hasSameLength = applicantAssessment?.answers?.length === assessmentDetails?.questions.length;
    let hasAnsweredAllQuestions = true;

    applicantAssessment.answers.forEach(answer => {
      if (answer.answerIds.length === 0) {
        hasAnsweredAllQuestions = false;
      }
    });

    return hasSameLength && hasAnsweredAllQuestions;
  }
}
