import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ComponentStore } from '@ngrx/component-store';
import { ApplicantTrackingSystemApi, CompanyApi, JobApi, JobApplicationApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { Observable, tap } from 'rxjs';
import { takeOne } from 'web/shared/util/rxjs-operator';

export interface JobDetailsState {
  jobId: string;
  jobDetails?: JobApi.JobDetails;
  jobDetailsView?: JobApi.JobDetailsView;
  successPopUpOpened: boolean;
  isJobDetailLoading: boolean;
  isApplyJobLoading: boolean;
}

@Injectable({ providedIn: 'root' })
export class JobDetailsViewModel extends ComponentStore<JobDetailsState> {
  public readonly vm$: Observable<JobDetailsState> = this.select(state => ({
    jobId: state.jobId,
    jobDetails: state.jobDetails,
    jobDetailsView: this.toJobDetailView(state.jobDetails),
    successPopUpOpened: state.successPopUpOpened,
    isJobDetailLoading: state.isJobDetailLoading,
    isApplyJobLoading: state.isApplyJobLoading,
  }));

  constructor(
    public readonly jobApiService: JobApiService,
    public readonly jobApplicationApiService: JobApplicationApiService,
    private readonly router: Router,
    public readonly alertService: AlertService,
    private readonly translocoService: TranslocoService,
  ) {
    super({
      jobId: '',
      successPopUpOpened: false,
      isJobDetailLoading: false,
      isApplyJobLoading: false,
    });
  }

  public setJobId(jobId: string): void {
    this.patchState({ jobId });
  }

  public getJobDetails(jobId: string): void {
    this.patchState({ isJobDetailLoading: true });

    this.jobApiService
      .getJobDetails(jobId)
      .pipe(
        takeOne(),
        tap(jobDetails => this.patchState({ jobDetails, isJobDetailLoading: false })),
      )
      .subscribe();
  }

  public createApplication(): void {
    this.patchState({ isApplyJobLoading: true });

    const jobDetails: JobApplicationApi.JobApplication = {
      jobId: String(this.get().jobId),
      source: ApplicantTrackingSystemApi.Source.INTERNAL,
    };

    this.jobApplicationApiService
      .create(jobDetails)
      .pipe(
        takeOne(),
        tap(jobApplication => {
          if (jobApplication.currentStep?.assessment) {
            this.router.navigate([`/jobs/assessment/${jobApplication.currentStep?.assessment.id}`]);
          } else {
            this.router.navigate(['../jobs']);
            this.alertService.success(this.translocoService.translate('domain.jobs.feature.apply.success-message'));
          }

          this.patchState({ isApplyJobLoading: false });
        }),
      )
      .subscribe();
  }

  public openCompanyDetail(): void {
    const { jobDetails } = this.get();

    this.router.navigate([`companies/${jobDetails?.company?.id}`]);
  }

  public toJobDetailView(jobDetail: JobApi.JobDetails | undefined): JobApi.JobDetailsView | undefined {
    if (jobDetail === undefined) {
      return undefined;
    }

    return {
      id: jobDetail.id,
      title: jobDetail.titleTextVariants,
      locationStr: jobDetail.address.city === 'Not applicable' ? '-' : `${jobDetail.address.city}`,
      location: jobDetail.address,
      companyLogoUrl: jobDetail.company?.avatarUrl ?? '',
      companyTitle: jobDetail.company?.name ?? '',
      companyId: jobDetail.company?.id ?? '',
      companySize: jobDetail.company?.companySize as CompanyApi.CompanySize,
      jobType: jobDetail.jobType.textVariants,
      description: jobDetail.descriptionTextVariants,
      compensations: jobDetail.compensations,
      employmentTypesTransloco: jobDetail.employmentTypes.map(employmentType =>
        JobApi.employmentTypeToHumanReadableValue(employmentType),
      ),
      images: jobDetail.jobBranding?.images.filter(image => !image.isHeader).map(image => image.previewUrl) ?? [],
      videoLink: jobDetail.jobBranding?.videoLink,
      headerImageUrl: jobDetail.jobBranding?.images.find(image => image.isHeader)?.previewUrl,
      jobs:
        jobDetail.jobs?.map(job => ({
          id: job.id,
          title: job.titleTextVariants,
          compensations: jobDetail.compensations,
          jobType: job.jobType.textVariants,
          address: job.address.city,
          employmentTypes: job.employmentTypes,
        })) ?? [],
    };
  }

  public destroyData(): void {
    this.patchState({ jobDetails: undefined, jobDetailsView: undefined });
  }

  public toggleSuccessPopUp(): void {
    const successPopUpOpened = this.get().successPopUpOpened;

    this.patchState({ successPopUpOpened: !successPopUpOpened });
  }
}
