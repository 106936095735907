import { Injectable } from '@angular/core';
import { PosthogApi } from '@web/shared/data-access/model';
import posthog from 'posthog-js';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';

@Injectable({ providedIn: 'root' })
export class PosthogService {
  constructor(protected readonly utmLocalStorageService: UtmLocalStorageService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public captureEvent(posthogEvent: PosthogApi.PostHogEvent, properties?: any): void {
    const utmData = this.utmLocalStorageService.getUtmData();
    if (Object.values(utmData).find(utm => utm.length > 0)) {
      properties = { ...properties, ...utmData };
    }

    posthog.capture(posthogEvent, properties);
  }
}
