import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { JobApi, TranslocoApi } from '@web/shared/data-access/model';
import { ButtonModule } from '@web/web/shared/ui/button';
import { EmojiTextPipe, TextVariantPipe } from '@web/web/shared/util/pipes';
import { FeatherModule } from 'angular-feather';
import { EmploymentTypeToReadablePipe } from './employment-type.pipe';
import { HtmlParserPipe } from './html-parser.pipe';
import { VariantFallbackPipePipe } from './variantFallbackPipe.pipe';
import JobDetails = JobApi.JobDetails;

@Component({
  selector: 'wh-ui-job-details',
  imports: [
    CommonModule,
    FeatherModule,
    ButtonModule,
    TranslocoModule,
    HtmlParserPipe,
    EmploymentTypeToReadablePipe,
    EmojiTextPipe,
    TextVariantPipe,
    VariantFallbackPipePipe,
  ],
  templateUrl: './job-details-ui.component.html',
  styleUrls: ['./job-details-ui.component.scss'],
})
export class JobDetailsUiComponent implements OnInit {
  @Input()
  public jobDetails: JobDetails;

  @Input()
  public currentLocale: TranslocoApi.Locale;

  @Input()
  public isApplicant = false;

  @Input()
  public isPromotionView = false;

  @Input()
  public promotionImage: string;
  @Input()
  public promotionCompanyName: string;
  @Input()
  public promotionCompanyAddress: string;
  @Input()
  public promotionCompanyLogo: string;

  @Output()
  public applyBtnClick = new EventEmitter<void>();

  @Output()
  public cancelBtnClick = new EventEmitter<void>();

  public isAtPageEnd = false;
  public imageNotLoading = false;

  public ngOnInit(): void {
    this.onScroll();
  }

  /**
   * Check if the applicant has scrolled to the bottom of the page
   */
  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  public onScroll(): void {
    const isAtPageBottom = window.innerHeight + window.scrollY >= document.body.scrollHeight;
    const contentFitsOnWindow = document.body.scrollHeight <= window.innerHeight;

    this.isAtPageEnd = contentFitsOnWindow || isAtPageBottom;
  }
}
