import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApplicantTrackingSystemApi,
  ApplicantTrackingSystemApiNew,
  AssessmentApi,
  JobApplicationApi,
  NoteApi,
  RecruitmentProcessApi,
  UtmApi,
} from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';
import FilterData = ApplicantTrackingSystemApi.FilterData;

@Injectable({
  providedIn: 'root',
})
export class JobApplicationApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/job-application`;
  }

  public getApplicationsByApplicant(): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/applicant`);
  }

  public getApplicationById(id: string): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application>(`${this.url}/applicant/${id}`);
  }

  public getApplicantsPendingAssessments(): Observable<AssessmentApi.AssessmentDetails[]> {
    return this.httpClient.get<AssessmentApi.AssessmentDetails[]>(`${this.url}/applicant/assessments-pending`);
  }

  public getApplicationsByApplicantId(applicantId: string): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/applicants/${applicantId}`);
  }

  public getMany(
    pageSize: number,
    currentPage: number,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Application>> {
    return this.httpClient.get<PaginateResult<ApplicantTrackingSystemApi.Application>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public applyForJobInternally(
    jobId: string,
    utm: UtmApi.UtmData | undefined,
  ): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.post<ApplicantTrackingSystemApi.Application>(`${this.url}/job/${jobId}/apply`, utm);
  }

  public create(jobApplication: JobApplicationApi.JobApplication): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.post<ApplicantTrackingSystemApi.Application>(`${this.url}`, jobApplication);
  }

  public createForAdmin(
    jobApplication: JobApplicationApi.JobApplication,
  ): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.post<ApplicantTrackingSystemApi.Application>(`${this.url}/admin`, jobApplication);
  }

  public updateApplications(applications: ApplicantTrackingSystemApi.Application[]): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/all`, applications);
  }

  public updateApplication(
    application: ApplicantTrackingSystemApi.Application,
    skipCommunication = false,
  ): Observable<void> {
    return this.httpClient.put<void>(`${this.url}?skipCommunication=${skipCommunication}`, application);
  }

  public getAllRecruitmentSteps(): Observable<RecruitmentProcessApi.RecruitmentStep[]> {
    return this.httpClient.get<RecruitmentProcessApi.RecruitmentStep[]>(`${this.url}/recruitmentSteps`);
  }

  public abort(abortApplication: JobApplicationApi.AbortApplication): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/abort`, abortApplication);
  }

  public getSearchResults(
    pageSize: number,
    page: number,
    searchTerm: string,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Application>> {
    return this.httpClient.get<PaginateResult<ApplicantTrackingSystemApi.Application>>(`${this.url}/search-many`, {
      params: new HttpParams().set('currentPage', page).set('pageSize', pageSize).set('searchTerm', searchTerm),
    });
  }

  public getFilters(): Observable<ApplicantTrackingSystemApi.FilterData> {
    return this.httpClient.get<ApplicantTrackingSystemApi.FilterData>(`${this.url}/filters`);
  }

  public fetchAtsList(
    pageSize: number,
    page: number,
    searchTerm: string,
    filterData: FilterData,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Applicant>> {
    return this.httpClient.post<PaginateResult<ApplicantTrackingSystemApi.Applicant>>(
      `${this.url}/ats/job-applications`,
      {
        currentPage: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
        filterData: filterData,
      },
    );
  }

  public delete(jobApplicationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${jobApplicationId}`);
  }

  public checkApplicationStatus(): Observable<JobApplicationApi.ApplicationsStatus> {
    return this.httpClient.get<JobApplicationApi.ApplicationsStatus>(`${this.url}/status`);
  }

  public createChangePhaseRequest(jobApplicationId: string, message: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${jobApplicationId}/change-phase-request`, { message });
  }

  public hideApplication(jobAppDetails: ApplicantTrackingSystemApi.Application | undefined): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/hide`, jobAppDetails);
  }

  public toggleApplicationVisibility(jobApplicationId: string, isHidden: boolean): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/${jobApplicationId}/toggle-visibility`, { isHidden });
  }

  public getManyByJobId(jobId: string): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/job/${jobId}`);
  }

  public getManyByCompanyId(companyId: string): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/company/${companyId}`);
  }

  public createNote(applicationId: string | undefined, applicantId: string, text: string): Observable<NoteApi.Note> {
    return this.httpClient.post<NoteApi.Note>(`${this.url}/note`, { applicationId, applicantId, text });
  }

  public updateNote({ noteId, text }: { noteId: string; text: string }): Observable<NoteApi.Note> {
    return this.httpClient.put<NoteApi.Note>(`${this.url}/note/${noteId}`, { text });
  }

  public deleteNote(noteId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/note/${noteId}`);
  }

  public updateHistoryStep(updateStep: RecruitmentProcessApi.UpdateHistoryStep): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/history-step`, updateStep);
  }

  public fetchApplicantProfileViaJobApplication(
    jobApplicationId: string,
    applicantId: string,
  ): Observable<ApplicantTrackingSystemApiNew.ApplicantProfile> {
    return this.httpClient.get<ApplicantTrackingSystemApiNew.ApplicantProfile>(
      `${this.url}/${jobApplicationId}/applicant-profile/${applicantId}`,
    );
  }

  public fetchNotes(applicantId: string): Observable<NoteApi.Note[]> {
    return this.httpClient.get<NoteApi.Note[]>(`${this.url}/note/${applicantId}`);
  }

  public fetchNotesForAts(applicantId: string, applicationIdList: string[]): Observable<NoteApi.Note[]> {
    return this.httpClient.post<NoteApi.Note[]>(`${this.url}/ats/note/${applicantId}`, {
      applicationIdList: applicationIdList,
    });
  }

  public fetchRecruitingHistory(
    jobApplicationId: string,
    applicantId: string,
  ): Observable<RecruitmentProcessApi.HistoryTree> {
    return this.httpClient.get<RecruitmentProcessApi.HistoryTree>(
      `${this.url}/${jobApplicationId}/recruitment-history/${applicantId}`,
    );
  }

  public fetchRecruitingHistoryForApplicantWithinCompany(
    applicantId: string,
  ): Observable<RecruitmentProcessApi.HistoryTree[]> {
    return this.httpClient.get<RecruitmentProcessApi.HistoryTree[]>(`${this.url}/recruitment-history/${applicantId}`);
  }

  public fetchJobApplicationList(
    searchData: JobApplicationApi.SearchData,
  ): Observable<PaginateResult<ApplicantTrackingSystemApiNew.JobApplicationListItem>> {
    return this.httpClient.post<PaginateResult<ApplicantTrackingSystemApiNew.JobApplicationListItem>>(
      `${this.url}/search-many-new`,
      searchData,
    );
  }

  public changePhase(
    changePhaseRequest: ApplicantTrackingSystemApiNew.ChangePhase,
  ): Observable<ApplicantTrackingSystemApiNew.JobApplicationListItem> {
    return this.httpClient.put<ApplicantTrackingSystemApiNew.JobApplicationListItem>(
      `${this.url}/change-phase`,
      changePhaseRequest,
    );
  }

  public fetchAvailablePhases(jobApplicationId: string): Observable<RecruitmentProcessApi.RecruitmentStepSimple[]> {
    return this.httpClient.get<RecruitmentProcessApi.RecruitmentStepSimple[]>(
      `${this.url}/${jobApplicationId}/available-phase-list`,
    );
  }

  public fetchApplicationPhases(applicantId: string): Observable<ApplicantTrackingSystemApiNew.ApplicationPhase[]> {
    return this.httpClient.get<ApplicantTrackingSystemApiNew.ApplicationPhase[]>(
      `${this.url}/${applicantId}/phase-info`,
    );
  }
}
