import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { AlertItem, AlertItemComponent } from '@web/web/shared/ui/alert-item';

@Component({
  selector: 'wh-s-alert-container',
  imports: [CommonModule, AlertItemComponent, WebCoreI18nModule],
  animations: [
    trigger('enterLeaveList', [
      transition(':leave', [animate('0.3s ease', style({ opacity: 0, height: 0, 'min-height': 0, margin: 0 }))]),
      transition(':enter', [
        style({ opacity: 0, height: 0, margin: '0px' }),
        animate('0.3s ease-in', style({ opacity: 1, height: '5rem', margin: '0.5rem 0' })),
      ]),
    ]),
  ],
  template: `
    <div class="list-wrapper">
      @for (item of items; track item.id; let index = $index) {
        <wh-s-alert-item
          @enterLeaveList
          [type]="item.type"
          [id]="item.id"
          [text]="item.text | transloco"
          [title]="item.title | transloco"
          (closeClick)="closeItem.emit($event)"
        />
      }
    </div>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;
      @import 'shared-root';

      :host {
        display: block;
        /*display: flex;*/
        /*justify-content: flex-start;*/
        /*align-items: center;*/
        /*flex-direction: column;*/
        position: fixed;
        right: 5rem;
        top: 6.5rem;
        z-index: 10000;
        height: 88vh;
        overflow: unset;
        pointer-events: none;
        min-width: 10rem;

        @include mx.df-screen(mobile) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          right: 0;
          top: 5.5rem;
          width: 100%;

          & > .list-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
          }
        }

        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }

        & > .list-wrapper {
          position: relative;
          width: 90%;
        }
      }
    `,
  ],
})
export class AlertContainerComponent {
  /**
   * Alert data that will be rendered on the screen
   */
  @Input()
  public items: AlertItem[];

  /**
   * Emits ID of item that will be closed
   */
  @Output()
  public closeItem = new EventEmitter<string>();
}
