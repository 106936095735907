import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicantExternalApplicationGuard {
  constructor(private readonly router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Object.keys(route.queryParams).includes('jobId')) {
      return this.router.navigate([`/external-application/${route.queryParams['jobId']}`], {
        queryParams: { ...route.queryParams, jobId: undefined },
        queryParamsHandling: 'merge',
      });
    } else return of(true);
  }
}
