import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-profile-settings-dropdown',
  imports: [CommonModule, FeatherModule, RouterLink, WebCoreI18nModule],
  templateUrl: './profile-settings-dropdown.component.html',
  styleUrl: './profile-settings-dropdown.component.scss',
})
export class ProfileSettingsDropdownComponent {}
