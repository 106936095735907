import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { LinkSanitizerPipe } from '@web/web/shared/ui/link-sanitizer';
import { videoLinkTransform } from '@web/web/shared/util/function';

@Component({
  selector: 'wh-s-profile-detail-video',
  imports: [CommonModule, LinkSanitizerPipe, WebCoreI18nModule],
  template: `
    <div *ngIf="hasTitle" class="title-wrapper">
      <h4 class="wh-header-h4">{{ 'domain.company.feature.profile.impressions' | transloco }}</h4>
    </div>

    @if (videoUrl) {
      <section class="video">
        <div class="video-container">
          <iframe *ngIf="videoUrl" [src]="videoLinkTransform(videoUrl) | linkSanitizer"></iframe>
        </div>
      </section>
    } @else {
      <!--      <p class="wh-paragraph-md">N/A</p>-->
    }
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;

      :host {
        display: inline-block;
        width: 100%;

        & .video {
          //height: 50rem;
          height: max-content;

          & > .title-wrapper {
            margin-top: 2rem;

            & > h4 {
              @include mx.df-screen(mobile) {
                font-size: 40px;
              }
            }
          }

          & > .video-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 35px;
            height: 0;
            overflow: hidden;

            & > iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    `,
  ],
})
export class ProfileDetailVideoComponent {
  @Input({
    required: true,
  })
  public videoUrl?: string;

  @Input()
  public hasTitle = true;

  protected readonly videoLinkTransform = videoLinkTransform;
}
