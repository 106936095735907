import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
//  TODO: Fix imports
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createCountdownTimer } from '@web/web/shared/util/function';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private readonly RESET_PASSWORD_TIMER_KEY = 'reset_password_timer_key';

  private readonly _timerValue = new BehaviorSubject<number>(0);
  public timerValue$: Observable<number> = this._timerValue.asObservable();

  constructor(@Inject(PLATFORM_ID) private _platformId: object) {
    //  Start timer in the background of app
    const valueFromLocalStorage = this.getTimerValueFromLocalStorage();

    if (!valueFromLocalStorage) {
      return;
    }

    this.createTimer(valueFromLocalStorage);
  }

  public createTimer(timerCountdown: number): void {
    this._timerValue.next(timerCountdown);

    createCountdownTimer(timerCountdown, counter => this.updateTimer(counter)).subscribe();
  }

  public isTimerActive(): boolean {
    if (isPlatformBrowser(this._platformId)) {
      return !!Number(localStorage.getItem(this.RESET_PASSWORD_TIMER_KEY));
    }

    return false;
  }

  public getTimerValueFromLocalStorage(): number {
    const timerValue = isPlatformBrowser(this._platformId) ? localStorage.getItem(this.RESET_PASSWORD_TIMER_KEY) : '0';

    return timerValue !== null ? Number(timerValue) : 0;
  }

  public updateTimer(counter: number): void {
    this._timerValue.next(counter);

    if (isPlatformBrowser(this._platformId)) {
      localStorage.setItem(this.RESET_PASSWORD_TIMER_KEY, `${counter}`);
    }
  }
}
