import { ApplicantApprenticeshipApi } from './applicant-apprenticeship-api.namespace';
import { ApplicantEducationApi } from './applicant-education-api.namespace';
import { ApplicantLanguageApi } from './applicant-language-api.namespace';
import { ApplicantTrackingSystemApi } from './applicant-tracking-system-api.namespace';
import { ApplicantWorkExperienceApi } from './applicant-work-experience-api.namespace';
import { AuthApi } from './auth-api.namespace';
import { CertificateApi } from './certificate-api.namespace';
import { CompensationApi } from './compensation-api.namespace';
import { DriverLicenseApi } from './driver-license-api.namespace';
import { HereMapsApi } from './here-maps.namespace';
import { JobApi } from './job-api.namespace';
import { NoteApi } from './note-api.namespace';
import { TranslocoApi } from './transloco-api.namespace';
import { UtmApi } from './utm.namespace';

export namespace ApplicantApi {
  export enum Role {
    APPLICANT = 'APPLICANT',
  }

  export const PROFILE_COMPLETENESS_THRESHOLD = 71;

  export interface Applicant {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl?: string;
    birthDate?: Date;
    gender?: Gender;
    phoneNumber?: string;
    otp?: string;
    password?: string;
    address?: HereMapsApi.AddressData;
    workExperience?: ApplicantWorkExperienceApi.WorkExperience;
    apprenticeShip?: ApplicantApprenticeshipApi.Apprenticeship;
    driverLicenses?: DriverLicenseApi.DriverLicense[];
    languages?: TranslocoApi.Language[];
    utm?: UtmApi.UtmData;
    zapier?: boolean;
    communicationLanguage?: TranslocoApi.Locale;
  }

  export interface ApplicantProfile {
    id: string;
    firstName: string;
    lastName: string;
    nationality: TranslocoApi.Nationality;
    workExperiences: ApplicantWorkExperienceApi.WorkExperience[];
    educations: ApplicantEducationApi.Education[];
    apprenticeships: ApplicantApprenticeshipApi.Apprenticeship[];
    licenses: DriverLicenseApi.DriverLicenseApplicant[];
    certificates: CertificateApi.Certificate[];
    documents: ApplicantApi.DocumentItem[];
    language: ApplicantLanguageApi.Language[];
    employmentTypes?: ApplicantApi.EmploymentType;
    workingTimes?: ApplicantApi.WorkingTimes;
    compensationPreference?: CompensationApi.Compensation;
    avatarUrl?: string;
    bannerUrl?: string;
    email?: string;
    phoneNumber?: string;
    birthDate?: Date;
    age?: number;
    address?: string;
    hereMapsAddress?: HereMapsApi.AddressData;
    isWillingToMove: boolean;
    isStudent: boolean;
    hasWorkPermit: boolean;
    createdAt?: Date;
    lastLogin?: Date;
    applications?: ApplicantTrackingSystemApi.Application[];
    noDriverLicenseCase?: boolean;
    notes?: NoteApi.Note[];
    additionalInformation?: AdditionalInformation;
  }

  export interface RegisterApplicant extends Applicant {
    password: string;
    phoneNumber: string;
  }

  export interface CreateApplicant {
    name: string;
    lastname: string;
    email: string;
  }

  export interface UpdateApplicant {
    id: string;
    name?: string;
    lastname?: string;
    email?: string;
    phone?: string;
    otp?: string;
  }

  export interface ApplicantPhoneRegistration {
    id: string;
    otp?: string;
  }

  export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE',
  }

  export function genderEnumToText(gender: Gender): string {
    switch (gender) {
      case Gender.FEMALE:
        return 'Female';
      case Gender.MALE:
        return 'Male';
      case Gender.DIVERSE:
        return 'Diverse';
    }
  }

  export interface WorkingTimes {
    shifts: ApplicantApi.Shift[];
  }

  export interface LegalInfo {
    id?: string;
    hasWorkPermit: boolean;
    hasTaxIdNumber: boolean;
    hasSocialSecurityNumber: boolean;
    hasHealthInsurance: boolean;
    isStudent: boolean;
    isWillingToMove: boolean;
  }

  export enum Shift {
    WORKING_DAYS_MORNING = 'WORKING_DAYS_MORNING',
    WORKING_DAYS_LATE = 'WORKING_DAYS_LATE',
    WORKING_DAYS_NIGHT = 'WORKING_DAYS_NIGHT',
    SATURDAY_MORNING = 'SATURDAY_MORNING',
    SATURDAY_LATE = 'SATURDAY_LATE',
    SATURDAY_NIGHT = 'SATURDAY_NIGHT',
    SUNDAY_MORNING = 'SUNDAY_MORNING',
    SUNDAY_LATE = 'SUNDAY_LATE',
    SUNDAY_NIGHT = 'SUNDAY_NIGHT',
  }

  // Registration steps interfaces:

  //  TODO: Check if needed
  export interface PersonalInformation {
    id?: string;
    firstName?: string;
    lastName?: string;
    nationality: TranslocoApi.Nationality;
    image?: File;
    gender: Gender;
    birthDate: Date;
    avatarUrl?: string;
  }

  export interface PersonalInformationV2 {
    firstName: string;
    lastName: string;
    address: string;
    hereMapsAddress?: HereMapsApi.AddressData;
    email: string;
    phoneNumber: string;
    nationality?: TranslocoApi.Nationality;
    dob?: Date;
    isWillingToMove: boolean;
    hasWorkPermit: boolean;
    isStudent: boolean;
  }

  export interface ApplicantLoginResponseDto extends AuthApi.BaseLoginResponseDto {
    role: Role;
    avatarUrl?: string;
    hasAutoSetPassword?: boolean;
  }

  export interface SelectedEmploymentType {
    selected: JobApi.EmploymentType[];
  }

  export interface SelfEmploymentData {
    companyName: string;
    address: HereMapsApi.AddressData;
    taxNumber: number;
    vatNumber: number;
    vehicleNumber: number;
  }

  export interface EmploymentType extends SelectedEmploymentType {
    selfEmployedData?: SelfEmploymentData;
  }

  export enum RegisterStep {
    APPRENTICESHIP = 'APPRENTICESHIP',
    WORK_EXPERIENCE = 'WORK_EXPERIENCE',
    DRIVER_LICENSE = 'DRIVER_LICENSE',
    LANGUAGE = 'LANGUAGE',
    ADDRESS = 'ADDRESS',
    PHONE_REGISTRATION = 'PHONE_REGISTRATION',
    PHONE_CONFIRMATION = 'PHONE_CONFIRMATION',
    CREATE_ACCOUNT_STEP_1 = 'CREATE_ACCOUNT_STEP_1',
    CREATE_ACCOUNT_STEP_2 = 'CREATE_ACCOUNT_STEP_2',
    ASSESSMENT = 'ASSESSMENT',
    SUCCESS = 'SUCCESS',
    PROCESSING_SOCIAL_REGISTRATION = 'PROCESSING_SOCIAL_REGISTRATION',
  }

  export enum DocumentFileFormat {
    PDF = 'application/pdf',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    BMP = 'image/bmp',
    TIFF = 'image/tiff',
    WEBP = 'image/webp',
    HEIC = 'image/heic',
  }

  export function findFileFormat(fileFormat: string): DocumentFileFormat | null {
    const isFound = Object.values(DocumentFileFormat).find(documentType => documentType === fileFormat);

    if (isFound) {
      return isFound as DocumentFileFormat;
    }

    return null;
  }

  export enum DocumentType {
    GOVERNMENT_ID_OR_PASSPORT = 'GOVERNMENT_ID_OR_PASSPORT',
    WORK_PERMIT = 'WORK_PERMIT',
    DRIVER_LICENSE = 'DRIVER_LICENSE',
    CV = 'CV',
    HEALTH_INSURANCE = 'HEALTH_INSURANCE',
    STUDENT_ENROLLMENT_CERTIFICATE = 'STUDENT_ENROLLMENT_CERTIFICATE',
    TRADE_BUSINESS_LICENSE = 'TRADE_BUSINESS_LICENSE',
    P_LICENSE = 'P_LICENSE',
    TRAINING_CERTIFICATE = 'TRAINING_CERTIFICATE',
    EMPLOYMENT_CERTIFICATE = 'EMPLOYMENT_CERTIFICATE',
  }

  export interface DocumentGroupView {
    type: DocumentType;
    title: string;
    documents: DocumentItemView[];
  }

  export interface DocumentTypeDropDownItem {
    documentType: DocumentType;
    documentTypeHumanReadable: string;
    isRequired: boolean;
    isSingleFileUpload: boolean;
    hasUploadedAssets: boolean;
  }

  export interface DocumentItem {
    id: string;
    assets: DocumentItemAsset[];
    type: DocumentType;
    createdAt: Date;
  }

  export interface DocumentItemView extends Partial<DocumentItem> {
    title: string;
  }

  export interface DocumentItemAsset {
    title: string;
    dateAdded: Date;
    fileFormat: DocumentFileFormat;
    url?: string;
  }

  export interface DownloadDocumentItemAsset {
    parentDocumentId: string;
    title: string;
    dateAdded: Date;
    fileFormat: DocumentFileFormat;
    url?: string;
  }

  export interface ApplicantProfessionalProfile {
    workExperiences: ApplicantWorkExperienceApi.WorkExperience[];
    educations: ApplicantEducationApi.Education[];
    apprenticeships: ApplicantApprenticeshipApi.Apprenticeship[];
    licenses: DriverLicenseApi.DriverLicenseApplicant[];
    certificates: CertificateApi.Certificate[];
    documents: ApplicantApi.DocumentItem[];
    language: ApplicantLanguageApi.Language[];
  }

  export const requiredDocumentList: DocumentItemView[] = [
    { type: DocumentType.GOVERNMENT_ID_OR_PASSPORT, title: 'shared.ui.document-type.GOVERNMENT_ID_OR_PASSPORT' },
    { type: DocumentType.DRIVER_LICENSE, title: 'shared.ui.document-type.DRIVER_LICENSE' },
    { type: DocumentType.WORK_PERMIT, title: 'shared.ui.document-type.WORK_PERMIT' },
  ];

  export const optionalDocumentList: DocumentItemView[] = [
    { type: DocumentType.CV, title: 'shared.ui.document-type.CV' },
    { type: DocumentType.HEALTH_INSURANCE, title: 'shared.ui.document-type.HEALTH_INSURANCE' },
    {
      type: DocumentType.STUDENT_ENROLLMENT_CERTIFICATE,
      title: 'shared.ui.document-type.STUDENT_ENROLLMENT_CERTIFICATE',
    },
    { type: DocumentType.TRADE_BUSINESS_LICENSE, title: 'shared.ui.document-type.TRADE_BUSINESS_LICENSE' },
    { type: DocumentType.P_LICENSE, title: 'shared.ui.document-type.P_LICENSE' },
    { type: DocumentType.TRAINING_CERTIFICATE, title: 'shared.ui.document-type.TRAINING_CERTIFICATE' },
    { type: DocumentType.EMPLOYMENT_CERTIFICATE, title: 'shared.ui.document-type.EMPLOYMENT_CERTIFICATE' },
  ];

  export function getRequiredListOfDocuments(isEuCitizen: boolean): DocumentItemView[] {
    if (isEuCitizen) {
      return requiredDocumentList.filter(requiredDocument => requiredDocument.type !== DocumentType.WORK_PERMIT);
    }

    return requiredDocumentList;
  }

  export enum AreaOfResponsibility {
    QUALITY_CONTROL = 'QUALITY_CONTROL',
    MACHINE_OPERATION = 'MACHINE_OPERATION',
    MAINTENANCE_AND_REPAIR = 'MAINTENANCE_AND_REPAIR',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    INVENTORY_MANAGEMENT = 'INVENTORY_MANAGEMENT',
    SAFETY_MANAGEMENT = 'SAFETY_MANAGEMENT',
    PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
    PRODUCTION_PLANNING = 'PRODUCTION_PLANNING',
    DATA_PROCESSING = 'DATA_PROCESSING',
    TEAM_LEADERSHIP = 'TEAM_LEADERSHIP',
    TRAINING_AND_DEVELOPMENT = 'TRAINING_AND_DEVELOPMENT',
    TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
    LOGISTICS = 'LOGISTICS',
    FACILITY_MANAGEMENT = 'FACILITY_MANAGEMENT',
    ENVIRONMENTAL_MANAGEMENT = 'ENVIRONMENTAL_MANAGEMENT',
    CUSTOM = 'CUSTOM',
  }

  export function toAreaOfResponsibilityTranslocoString(areaOfResponsibility: AreaOfResponsibility): string {
    switch (areaOfResponsibility) {
      case AreaOfResponsibility.QUALITY_CONTROL:
        return 'shared.ui.area-of-responsibility.quality-control';
      case AreaOfResponsibility.MACHINE_OPERATION:
        return 'shared.ui.area-of-responsibility.machine-operation';
      case AreaOfResponsibility.MAINTENANCE_AND_REPAIR:
        return 'shared.ui.area-of-responsibility.maintenance-and-repair';
      case AreaOfResponsibility.CUSTOMER_SERVICE:
        return 'shared.ui.area-of-responsibility.customer-service';
      case AreaOfResponsibility.INVENTORY_MANAGEMENT:
        return 'shared.ui.area-of-responsibility.inventory-management';
      case AreaOfResponsibility.SAFETY_MANAGEMENT:
        return 'shared.ui.area-of-responsibility.safety-management';
      case AreaOfResponsibility.PROJECT_MANAGEMENT:
        return 'shared.ui.area-of-responsibility.project-management';
      case AreaOfResponsibility.PRODUCTION_PLANNING:
        return 'shared.ui.area-of-responsibility.production-planning';
      case AreaOfResponsibility.DATA_PROCESSING:
        return 'shared.ui.area-of-responsibility.data-processing';
      case AreaOfResponsibility.TEAM_LEADERSHIP:
        return 'shared.ui.area-of-responsibility.team-leadership';
      case AreaOfResponsibility.TRAINING_AND_DEVELOPMENT:
        return 'shared.ui.area-of-responsibility.training-and-development';
      case AreaOfResponsibility.TECHNICAL_SUPPORT:
        return 'shared.ui.area-of-responsibility.technical-support';
      case AreaOfResponsibility.LOGISTICS:
        return 'shared.ui.area-of-responsibility.logistics';
      case AreaOfResponsibility.FACILITY_MANAGEMENT:
        return 'shared.ui.area-of-responsibility.facility-management';
      case AreaOfResponsibility.ENVIRONMENTAL_MANAGEMENT:
        return 'shared.ui.area-of-responsibility.environmental-management';
      case ApplicantApi.AreaOfResponsibility.CUSTOM:
        return '';
    }
  }

  export interface AreaOfResponsibilitySelectItem {
    enumValue: AreaOfResponsibility;
    translocoTitle: string;
  }

  export function getAreaOfResponsibilitiesAsTransolocoList(): AreaOfResponsibilitySelectItem[] {
    const areaOfResponsibilityKeys = Object.keys(AreaOfResponsibility) as (keyof typeof AreaOfResponsibility)[];

    return areaOfResponsibilityKeys
      .map(key => {
        const enumValue = AreaOfResponsibility[key];
        const translocoTitle = toAreaOfResponsibilityTranslocoString(enumValue);

        return {
          enumValue,
          translocoTitle,
        };
      })
      .filter(selectItem => selectItem.enumValue !== AreaOfResponsibility.CUSTOM);
  }

  export enum ProfileItem {
    PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
    WORK_EXPERIENCE = 'WORK_EXPERIENCE',
    APPRENTICESHIP = 'APPRENTICESHIP',
    EDUCATION = 'EDUCATION',
    LICENSE_CERTIFICATE = 'LICENSE_CERTIFICATE',
    LANGUAGE = 'LANGUAGE',
    DOCUMENTS = 'DOCUMENTS',
  }

  export interface ApplicantListFilter {
    address: HereMapsApi.AddressZip;
    distance: string;
    workExperience: string[];
    apprenticeship: string[];
    driverLicenses: DriverLicenseApi.DriverLicenseType[];
    englishLevel: TranslocoApi.LanguageLevel | undefined;
    germanLevel: TranslocoApi.LanguageLevel | undefined;
  }

  export interface AdditionalInformation {
    lastLogin: Date | undefined;
    registered: Date | undefined;
    emailVerifiedAt: Date | undefined;
    utmData: UtmApi.UtmData | undefined;
    utmId: string | undefined;
    active: boolean;
    zapier: boolean;
  }

  export interface ApplicantJobTarget {
    job: JobApi.JobDetailDropdown | undefined;
    filters: Partial<ApplicantApi.ApplicantListFilter> | undefined;
    searchTerm: string | undefined;
    maxApplicantCount: number;
  }

  export interface TargetedApplicantsCount {
    filteredApplicants: number;
    alreadyRequestedApplicants: number;
  }
}
