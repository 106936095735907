import { Injectable } from '@angular/core';
import { UtmApi } from '@web/shared/data-access/model';

@Injectable({ providedIn: 'root' })
export class UtmLocalStorageService {
  public utmProperties: Record<string, string | undefined> = {};

  public updateUtmProperties(
    utm_campaign_data_key?: string,
    utm_source_data_key?: string,
    utm_content_data_key?: string,
    utm_medium_data_key?: string,
    utm_term_data_key?: string,
  ): void {
    this.utmProperties = {
      utm_campaign_data_key,
      utm_source_data_key,
      utm_content_data_key,
      utm_medium_data_key,
      utm_term_data_key,
    };

    // Set UTM parameters
    Object.entries(this.utmProperties).forEach(([utmKey, utmValue]) => {
      localStorage.setItem(utmKey, utmValue ?? localStorage.getItem(utmKey) ?? '');
    });
  }

  public getUtmData(): UtmApi.UtmData {
    return {
      utmCampaign: localStorage.getItem('utm_campaign_data_key') ?? '',
      utmSource: localStorage.getItem('utm_source_data_key') ?? '',
      utmContent: localStorage.getItem('utm_content_data_key') ?? '',
      utmMedium: localStorage.getItem('utm_medium_data_key') ?? '',
      utmTerm: localStorage.getItem('utm_term_data_key') ?? '',
    };
  }

  public removeUtmData(): void {
    localStorage.removeItem('utm_campaign_data_key');
    localStorage.removeItem('utm_source_data_key');
    localStorage.removeItem('utm_content_data_key');
    localStorage.removeItem('utm_medium_data_key');
    localStorage.removeItem('utm_term_data_key');
  }
}
