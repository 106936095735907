<section class="topic">
  <div *ngIf="promotionCompanyLogo">
    <img class="imageLogo" src="{{ promotionCompanyLogo }}" alt="" />
  </div>
  <h1 class="wh-header-lg">
    {{ jobDetails.titleTextVariants | variantFallbackPipe: currentLocale }}
  </h1>
</section>
<div *ngIf="!isPromotionView" class="badge-container">
  <div class="badge">{{ jobDetails.jobType.textVariants | textVariant }}</div>
  <div class="badge">
    <i-feather name="map-pin"></i-feather>{{ jobDetails.address.city }}, {{ jobDetails.address.countryCode }}
  </div>
  <div class="badge"><i-feather name="briefcase"></i-feather>{{ jobDetails.company?.name }}</div>
</div>
<section>
  <div *ngIf="!isPromotionView" class="salary-employment-type">
    <div class="salary">
      <ng-container *ngFor="let c of jobDetails?.compensations">
        <div *ngIf="c.compensationType === 'SALARY'">
          <h5>{{ 'domain.jobs.feature.job-details.salary.title' | transloco }}</h5>
          <p>{{ c.amountTo }} {{ c.currency }} ({{ c.interval }})</p>
        </div>
      </ng-container>

      <ng-container *ngFor="let c of jobDetails?.compensations">
        <div *ngIf="c.compensationType === 'SALARY_RANGE'">
          <h5>{{ 'domain.jobs.feature.job-details.salary.range' | transloco }}</h5>
          <p>{{ c.amountFrom }} - {{ c.amountTo }} {{ c.currency }} ({{ c.interval }})</p>
        </div>
      </ng-container>
      <ng-container *ngFor="let c of jobDetails?.compensations">
        <div *ngIf="c.compensationType === 'BONUS' && c.amountFrom > 0">
          <h5>{{ 'domain.jobs.feature.job-details.salary.bonus-title' | transloco }}</h5>
          <p>{{ c.amountTo }} {{ c.currency }} ({{ c.interval }})</p>
        </div>
      </ng-container>
    </div>
    <div class="employment-type">
      <h5>{{ 'domain.jobs.feature.job-details.employment-types.title' | transloco }}</h5>
      <ul *ngFor="let empType of jobDetails?.employmentTypes">
        <li>{{ empType | employmentTypeToReadable }}</li>
      </ul>
    </div>
  </div>
</section>
<section *ngIf="isPromotionView">
  <h1 class="wh-header-md" *ngIf="promotionCompanyName">{{ promotionCompanyName | emojiText }}</h1>
  <div class="imageContainer" *ngIf="promotionImage">
    <img class="jobImage" src="{{ promotionImage }}" alt="" (error)="imageNotLoading = true" />
  </div>
  <div class="imageContainer" *ngIf="!promotionImage || imageNotLoading">
    <img class="defaultImage" src="/assets/image/intro-1.png" alt="company" />
  </div>
  <h3 class="wh-header-sm" *ngIf="promotionCompanyAddress">{{ promotionCompanyAddress }}</h3>
</section>
<hr />
<section>
  <h3 class="header">{{ 'domain.jobs.feature.job-details.job-description-title' | transloco }}</h3>
  <div
    [innerHTML]="jobDetails.shortDescriptionTextVariants | variantFallbackPipe: currentLocale | emojiText | htmlParser"
  ></div>
</section>
<section class="location">
  <ng-container *ngIf="!isPromotionView && jobDetails.address">
    <h3>{{ 'domain.jobs.feature.job-details.location-title' | transloco }}</h3>
    <p>{{ jobDetails.address.city }}, {{ jobDetails.address.countryCode }}</p>
  </ng-container>
</section>
<div [class.promo]="isPromotionView" class="actions-container">
  <div class="actions">
    <wh-s-button
      class="apply"
      label="{{ 'domain.jobs.feature.job-details.actions.apply-label' | transloco }}"
      (clickAction)="applyBtnClick.emit()"
    ></wh-s-button>
    <wh-s-button
      *ngIf="isApplicant"
      class="discard"
      label="{{ 'domain.jobs.feature.job-details.actions.cancel-label' | transloco }}"
      variant="zero"
      [disabled]="!isAtPageEnd"
      (clickAction)="cancelBtnClick.emit()"
    ></wh-s-button>
  </div>
</div>
