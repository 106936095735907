import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from '@web/web/shared/ui/button';
import { LinkSanitizerPipe } from '@web/web/shared/ui/link-sanitizer';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-job-detail-info-card',
  imports: [CommonModule, FeatherModule, ButtonModule, LinkSanitizerPipe],
  template: `
    <div class="card-wrapper">
      <div class="title-wrapper">
        <div *ngIf="avatar" class="img-wrapper">
          <img [src]="avatar | linkSanitizer" alt="" />
        </div>

        <h5 class="wh-header-h4 title">{{ title }}</h5>
      </div>

      <div class="main-content">
        <ng-content select="[slot=content]"></ng-content>
      </div>

      <div *ngIf="ctaTitle" class="cta-wrapper">
        <wh-s-button
          [variant]="ctaVariant"
          [elevated]="false"
          [label]="ctaTitle"
          (clickAction)="ctaClicked.emit()"
        ></wh-s-button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        width: inherit;
        height: max-content;
        background-color: white;

        & > .card-wrapper {
          padding: 1rem;
          border-radius: 0.5rem;
          /*border: 1px solid grey;*/
          border: solid 1px var(--grey-1);

          & > .title-wrapper {
            display: flex;
            justify-items: center;
            align-items: center;
            max-width: 90%;
            overflow: hidden;
            word-break: break-word;
            margin: 0 0 0.5rem 0;

            & > .img-wrapper {
              & > img {
                width: 3rem;
              }
            }

            & > .title {
              font-size: 2rem;
              /*margin: 0 0 0 0.5rem;*/
              margin: 0;
            }
          }

          & > .cta-wrapper {
            width: 100%;
            margin: 0.5rem 0;

            & > wh-s-button {
              width: 100%;
            }
          }
        }
      }
    `,
  ],
})
export class JobDetailInfoCardComponent {
  @Input({
    required: true,
  })
  public title: string;

  @Input()
  public avatar?: string;

  @Input()
  public ctaTitle?: string;

  @Input()
  public ctaVariant: 'zero' | 'primary' | 'secondary' | 'success' | 'danger' = 'secondary';

  @Output()
  public ctaClicked = new EventEmitter();
}
