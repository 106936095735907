import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ApplicantApi, AuthApi, PosthogApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoginViewModel } from '@web/web/core/auth/data-access';
import { AimwelService } from '@web/web/shared/data-access/aimwel';
import { AlertService } from '@web/web/shared/data-access/alert';
import { ApplicantApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { PosthogService } from 'posthog';
import { EMPTY, catchError, take, tap } from 'rxjs';
import { ApplicantAuthViewModel } from './applicant-auth.viewmodel';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let fbq: any;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class ApplicantLoginViewModel extends LoginViewModel {
  protected defaultRedirectUrl = '/dashboard';

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    private readonly applicantApiService: ApplicantApiService,
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    private readonly alertService: AlertService,
    private readonly jobApplicationApiService: JobApplicationApiService,
    private readonly translocoService: TranslocoService,
    private readonly aimwelService: AimwelService,
    private readonly applicantPosthogService: PosthogService,
  ) {
    super(formBuilder, router);
  }

  public submit(): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.LOGIN, { method: 'email' });

    const loginData = this.getLoginData();

    if (!loginData) {
      return;
    }

    this.applicantApiService
      .login({
        ...loginData,
        client: AuthApi.Client.APPLICANT_APP,
      })
      .pipe(tap(loginResponseDto => this.handleLogin(loginResponseDto)))
      .subscribe();
  }

  private handleLogin(loginResponseDto: ApplicantApi.ApplicantLoginResponseDto, removeQueryParams = false): void {
    if (removeQueryParams) {
      this.router.navigate([this.defaultRedirectUrl], {
        queryParams: { token: '' },
        queryParamsHandling: 'replace',
        replaceUrl: true,
      });
    } else this.redirect();

    this.applicantAuthViewModel.storeLoginData(loginResponseDto);

    const externalApplicationData = localStorage.getItem(this.applicantAuthViewModel.externalApplicationDataKey);

    if (externalApplicationData) {
      const data = JSON.parse(externalApplicationData);

      this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.APPLICANT_REGISTRATION_EXTERNAL, {
        jobId: data.jobId,
      });

      this.aimwelService.sendAimwelApplicationCompleteEvent();

      if (gtag) {
        gtag('event', 'conversion', {
          send_to: 'AW-375648535/tuxJCNr0r40CEJfij7MB',
        });
      }
      try {
        if (fbq) {
          fbq('track', 'Purchase', {
            value: 1.0,
            currency: 'EUR',
            content_ids: data.jobId ? [data.jobId] : [],
            content_type: 'product',
          });
          fbq('track', 'Lead', {
            content_ids: data.jobId ? [data.jobId] : [],
            value: 1.0,
            currency: 'EUR',
          });
        }
      } catch (e) {
        // check what's the best way to handle this case
        // eslint-disable-next-line no-console
        console.warn('fbq not defined');
      }

      this.jobApplicationApiService
        .create(data)
        .pipe(
          take(1),
          tap(jobApplication => {
            if (jobApplication.currentStep?.assessment) {
              this.router.navigate([`/jobs/assessment/${jobApplication.currentStep?.assessment.id}`]);
            }

            this.applicantAuthViewModel.removeExternalApplicationData();
          }),
          catchError(err => {
            if (err.status === 409) {
              this.applicantAuthViewModel.removeExternalApplicationData();
            }

            return EMPTY;
          }),
        )
        .subscribe();

      return;
    }

    const alertMsg = this.translocoService.translate('core.auth.feature.login.success-alert');
    this.alertService.success(`${alertMsg} ${loginResponseDto.firstName}`);
  }

  public redirectToGoogleLogin(): void {
    location.href = `${process.env['BASE_URL']}/applicants/${process.env['GOOGLE_LOGIN_REDIRECT_URL']}`;
  }

  public loginWithGoogle(token: string): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.LOGIN, { method: 'google' });

    this.applicantApiService
      .googleLogin(token)
      .pipe(
        tap(loginResponseDto => {
          this.handleLogin(loginResponseDto, true);
        }),
      )
      .subscribe();
  }

  public handleFailedGoogleLogin(): void {
    this.router.navigate(['/auth/login']);
    this.alertService.error(`core.auth.feature.login.no-acc-error-alert`);
  }

  public handleDeniedGooglePermission(): void {
    this.router.navigate(['/auth/login']);
    this.alertService.error(`core.auth.feature.login.google-permission-denied-error-alert`);
  }
}
