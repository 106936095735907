import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AlertService } from '@web/web/shared/data-access/alert';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';

@Component({
  selector: 'wh-applicant-root',
  template: `
    <!-- Global progress bar feature -->
    @if (loadingViewModel.isLoading$ | async) {
      <wh-s-progress-bar />
    }

    <!-- Global alert feature, use ** alertService ** anywhere in app to show alerts -->
    @if (alertService.vm$ | async; as vm) {
      <wh-s-alert-container [items]="vm.items" (closeItem)="alertService.removeAlert($event)" />
    }

    <router-outlet></router-outlet>
  `,
  styles: [
    `
      :host {
        & > wh-s-progress-bar {
          position: sticky;
          top: 0;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class AppComponent {
  constructor(
    public readonly loadingViewModel: LoadingViewModel,
    public readonly alertService: AlertService,
  ) {}
}
