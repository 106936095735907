import { ApplicantTrackingSystemApi } from './applicant-tracking-system-api.namespace';
import { AssessmentApi } from './assessment.namespace';
import { NoteApi } from './note-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace RecruitmentProcessApi {
  export interface HistoryTree {
    jobApplicationId: string;
    steps: HistoryStep[];
    title: TextVariantApi.TextVariant[];
  }

  export interface HistoryStep {
    id: string;
    name: string;
    movedBy?: ApplicantTrackingSystemApi.MovedBy;
    enteredOn: Date;
    isHidden: boolean;
    notes?: NoteApi.Note[];
    noHireReason?: string;
  }

  export interface UpdateHistoryStep {
    oldStep: HistoryStep;
    newStepId: string;
  }

  export enum RecruitmentStepType {
    APPLICATION = 'APPLICATION',
    HIRE = 'HIRE',
    QUALIFICATION = 'QUALIFICATION',
    NO_HIRE = 'NO_HIRE',
    CUSTOM = 'CUSTOM',
    ACCEPTED = 'ACCEPTED',
    FIRST_INTERVIEW = 'FIRST_INTERVIEW',
    SECOND_INTERVIEW = 'SECOND_INTERVIEW',
    INTERVIEW = 'INTERVIEW', // Used for user (custom) defined interviews
    TRIAL_WORK = 'TRIAL_WORK',
    ASSESSMENT = 'ASSESSMENT',
    PICK = 'PICK',
    POTENTIAL_PICK = 'POTENTIAL_PICK',
    CONTRACT_SENT = 'CONTRACT_SENT',
    NOT_REACHABLE = 'NOT_REACHABLE',
    AWAITING_DOCUMENTS = 'AWAITING_DOCUMENTS',
  }

  export function defaultRecruitmentStepTypeToTransloco(recruitmentStepType: RecruitmentStepType): string {
    switch (recruitmentStepType) {
      case RecruitmentStepType.APPLICATION:
        return 'shared.default-recruitment-step.application';
      case RecruitmentStepType.ASSESSMENT:
        return 'shared.default-recruitment-step.assessment';
      case RecruitmentStepType.QUALIFICATION:
        return 'shared.default-recruitment-step.qualification';
      case RecruitmentStepType.POTENTIAL_PICK:
        return 'shared.default-recruitment-step.potential';
      case RecruitmentStepType.PICK:
        return 'shared.default-recruitment-step.pick';
      case RecruitmentStepType.ACCEPTED:
        return 'shared.default-recruitment-step.accepted';
      case RecruitmentStepType.NOT_REACHABLE:
        return 'shared.default-recruitment-step.not-reachable';
      case RecruitmentStepType.FIRST_INTERVIEW:
        return 'shared.default-recruitment-step.first-interview';
      case RecruitmentStepType.SECOND_INTERVIEW:
        return 'shared.default-recruitment-step.second-interview';
      case RecruitmentStepType.TRIAL_WORK:
        return 'shared.default-recruitment-step.trial-work';
      case RecruitmentStepType.AWAITING_DOCUMENTS:
        return 'shared.default-recruitment-step.awaiting-documents';
      case RecruitmentStepType.CONTRACT_SENT:
        return 'shared.default-recruitment-step.contract-sent';
      case RecruitmentStepType.HIRE:
        return 'shared.default-recruitment-step.hire';
      case RecruitmentStepType.NO_HIRE:
        return 'shared.default-recruitment-step.no-hire';
      default:
        return '';
    }
  }

  export function noHireReasonToTranslocoString(reason: NoHireReason): string {
    switch (reason) {
      case NoHireReason.NO_INTERVIEW_RESPONSE:
        return 'shared.ui.no_hire_reason.no_interview_response';
      case NoHireReason.NO_INTEREST:
        return 'shared.ui.no_hire_reason.no_interest';
      case NoHireReason.INSUFFICIENT_LANGUAGE_SKILLS:
        return 'shared.ui.no_hire_reason.insufficient_language_skills';
      case NoHireReason.DISTANCE:
        return 'shared.ui.no_hire_reason.distance';
      case NoHireReason.NO_CONDUCT_CERTIFICATE:
        return 'shared.ui.no_hire_reason.no_conduct_certificate';
      case NoHireReason.NO_DRIVER_LICENSE:
        return 'shared.ui.no_hire_reason.no_driver_license';
      case NoHireReason.LEGAL_REQUIREMENTS:
        return 'shared.ui.no_hire_reason.legal_requirements';
      case NoHireReason.SALARY_EXPECTATIONS:
        return 'shared.ui.no_hire_reason.salary_expectations';
      case NoHireReason.LACK_OF_EXPERIENCE:
        return 'shared.ui.no_hire_reason.lack_of_experience';
      case NoHireReason.NO_TEAM_FIT:
        return 'shared.ui.no_hire_reason.no_team_fit';
      case NoHireReason.INTERVIEW_PERFORMANCE:
        return 'shared.ui.no_hire_reason.interview_performance';
      case NoHireReason.NO_DEMAND:
        return 'shared.ui.no_hire_reason.no_demand';
      case NoHireReason.ASSESSMENT_NOT_PASSED:
        return 'shared.ui.no_hire_reason.assessment_not_passed';
      case NoHireReason.CONTRACT_REJECTED:
        return 'shared.ui.no_hire_reason.contract_rejected';
      case NoHireReason.ALREADY_HIRED:
        return 'shared.ui.no_hire_reason.already_hired';
      case NoHireReason.OVERQUALIFIED:
        return 'shared.ui.no_hire_reason.overqualified';
      case NoHireReason.OTHER:
        return 'shared.ui.no_hire_reason.other';
      case NoHireReason.NO_RESPONSE:
        return 'shared.ui.no_hire_reason.no_response';
      default:
        return '';
    }
  }

  export function applicantNoHireReasonToTranslocoString(reason: ApplicantNoHireReason): string {
    switch (reason) {
      case ApplicantNoHireReason.LOW_SALARY:
        return 'shared.ui.abort-application-reason.LOW_SALARY';
      case ApplicantNoHireReason.DISTANCE:
        return 'shared.ui.abort-application-reason.DISTANCE';
      case ApplicantNoHireReason.ALREADY_HAD_INTERVIEW:
        return 'shared.ui.abort-application-reason.ALREADY_HAD_INTERVIEW';
      case ApplicantNoHireReason.NOT_INTERESTED:
        return 'shared.ui.abort-application-reason.NOT_INTERESTED';
      case ApplicantNoHireReason.NOT_APPEALING_JOB_DESCRIPTION:
        return 'shared.ui.abort-application-reason.NOT_APPEALING_JOB_DESCRIPTION';
      case ApplicantNoHireReason.COMPANY_CULTURE:
        return 'shared.ui.abort-application-reason.COMPANY_CULTURE';
      case ApplicantNoHireReason.WORKING_HOURS_DO_NOT_FIT:
        return 'shared.ui.abort-application-reason.WORKING_HOURS_DO_NOT_FIT';
      case ApplicantNoHireReason.MISSING_BENEFITS:
        return 'shared.ui.abort-application-reason.MISSING_BENEFITS';
      case ApplicantNoHireReason.MISSING_DEVELOPMENT_OPPORTUNITY:
        return 'shared.ui.abort-application-reason.MISSING_DEVELOPMENT_OPPORTUNITY';
      case ApplicantNoHireReason.OTHER:
        return 'shared.ui.abort-application-reason.OTHER';
      default:
        return '';
    }
  }

  export function defaultRecruitmentStepTypeToTranslocoString(type: RecruitmentStepType): string {
    switch (type) {
      case RecruitmentProcessApi.RecruitmentStepType.APPLICATION:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.application';
      case RecruitmentProcessApi.RecruitmentStepType.HIRE:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.hire';
      case RecruitmentProcessApi.RecruitmentStepType.NO_HIRE:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.no-hire';
      case RecruitmentProcessApi.RecruitmentStepType.PICK:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.pick';
      case RecruitmentProcessApi.RecruitmentStepType.POTENTIAL_PICK:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.potential-pick';
    }

    return '';
  }

  export interface RecruitmentProcess {
    id: string;
    steps: RecruitmentStep[];
  }

  export interface CreateRecruitmentStep {
    title: string;
    translocoTitle?: string;
    positionIndex: number;
    isHidden: boolean;
    syncActive: boolean;
    externalJobId?: string | null;
    recruitmentProcessType: RecruitmentStepType;
    autoMoveHere?: boolean;
  }

  export interface RecruitmentStep extends CreateRecruitmentStep {
    id: string;
    createdAt?: Date;
    timeSpentInStep?: string;
    assessment?: AssessmentApi.Assessment;
  }

  export interface RecruitmentStepSimple {
    id: string;
    title: string;
    translocoTitle?: string;
    type: RecruitmentProcessApi.RecruitmentStepType;
  }

  export enum NoHireReason {
    NO_INTERVIEW_RESPONSE = 'Candidate did not respond after the interview',
    NO_INTEREST = 'Candidate not interested anymore',
    INSUFFICIENT_LANGUAGE_SKILLS = 'Candidate did not have sufficient language skills',
    DISTANCE = 'Candidate too far away',
    NO_CONDUCT_CERTIFICATE = 'Missing certificate of conduct',
    NO_DRIVER_LICENSE = 'Missing Driver License',
    LEGAL_REQUIREMENTS = 'Candidate did not meet legal requirements',
    SALARY_EXPECTATIONS = 'Candidate did not meet salary expectations',
    LACK_OF_EXPERIENCE = 'Lack of experience',
    NO_TEAM_FIT = 'Candidate is not a fit for the team',
    INTERVIEW_PERFORMANCE = 'Poor Interview Performance',
    NO_DEMAND = 'No demand from the company',
    ASSESSMENT_NOT_PASSED = 'Candidate did not pass the assessment',
    CONTRACT_REJECTED = 'Candidate rejected the contract',
    ALREADY_HIRED = 'Candidate already hired for other position',
    OVERQUALIFIED = 'Candidate is overqualified',
    OTHER = 'Other',
    NO_RESPONSE = 'Candidate did not respond',
  }

  export const NO_HIRE_REASON_LIST: RecruitmentProcessApi.NoHireReason[] = Object.keys(
    RecruitmentProcessApi.NoHireReason,
  )
    .filter(key => isNaN(Number(key)))
    .map(key => RecruitmentProcessApi.NoHireReason[key as keyof object])
    .filter(val => typeof val === 'number' || typeof val === 'string');

  export enum ApplicantNoHireReason {
    LOW_SALARY = 'Low salary',
    DISTANCE = 'Too far away',
    NOT_INTERESTED = 'Not interested',
    NOT_APPEALING_JOB_DESCRIPTION = 'Not appealing job description',
    COMPANY_CULTURE = 'Company culture',
    ALREADY_HAD_INTERVIEW = 'Already had interview',
    WORKING_HOURS_DO_NOT_FIT = 'Working hours do not fit',
    MISSING_BENEFITS = 'Missing benefits',
    MISSING_DEVELOPMENT_OPPORTUNITY = 'Missing development opportunity',
    OTHER = 'Other',
  }
}
