import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { FeatherIconType } from '@web/web/shared/ui/feather-icon';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-alert-item',
  template: `
    <div
      role="alert"
      class="alert-item alert-{{ type }} d-flex"
      [class.clickable]="isClickable"
      [ngClass]="classes"
      (click)="clickAlert()"
    >
      <div class="left-icon-wrapper">
        @if (icon) {
          <i-feather [name]="icon"></i-feather>
        }
      </div>

      <div class="text-wrapper">
        <div class="alert--text text-title">{{ title | transloco }}</div>
        <div class="alert--text text-secondary">{{ text | transloco }}</div>
      </div>

      <div class="right-icon-wrapper" (click)="closeClick.emit(id)">
        <i-feather name="x"></i-feather>
      </div>
    </div>
  `,
  styleUrls: ['./alert-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FeatherModule, WebCoreI18nModule],
})
export class AlertItemComponent implements OnInit {
  /**
   * Type/Color scheme of the alert.
   */
  @Input()
  public type: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'light' | 'dark' | 'danger' = 'success';

  /**
   * Text to display in the alert.
   */
  @Input()
  public text!: string;

  /**
   * Text title to display in the alert.
   */
  @Input()
  public title?: string;

  /**
   * Text to display in the alert.
   */
  @Input()
  public id!: string;

  /**
   * Icon to display in the alert.
   */
  @Input()
  public icon?: FeatherIconType;

  @Input()
  public variant: 'toastr' | 'other' = 'toastr';

  /**
   * Emits when the alert is clicked.
   */
  @Output()
  public clickAction = new EventEmitter<void>();

  /**
   * Emits when the alert is clicked.
   */
  @Output()
  public closeClick = new EventEmitter<string>();

  public ngOnInit(): void {
    switch (this.type) {
      case 'success':
        this.icon = 'check-circle';
        break;
      case 'info':
        this.icon = 'info';
        break;
      case 'warning':
        this.icon = 'alert-triangle';
        break;
      case 'danger':
        this.icon = 'alert-triangle';
        break;
      default:
        break;
    }
  }

  public get isClickable(): boolean {
    return this.clickAction.observers.length > 0;
  }

  public clickAlert(): void {
    this.clickAction.emit();
  }

  public get classes(): string[] {
    return [`wh-s-alert--${this.variant}`];
  }
}
