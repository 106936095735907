import { Injectable, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

export interface LoginData {
  email: string;
  password: string;
}

@Injectable()
export abstract class LoginViewModel {
  public formGroup: FormGroup;
  private redirectUrl: null | string = null;
  private redirectParams: undefined | Params = undefined;
  protected abstract defaultRedirectUrl: string;

  public activatedRoute = inject(ActivatedRoute);

  protected constructor(
    protected readonly formBuilder: FormBuilder,
    protected readonly router: Router,
  ) {}

  /**
   * Builds default form
   *
   */
  public buildForm(): void {
    this.formGroup = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Extracts valid login data from form
   *
   * @protected
   */
  protected getLoginData(): LoginData | null {
    if (!this.formGroup.valid) {
      return null;
    }

    const { email, password } = this.formGroup.value;

    return {
      email,
      password,
    };
  }

  /**
   * Custom implementation of submit
   *
   * @protected
   */
  protected abstract submit(data?: unknown): void;

  public setRedirectUrl(url: string, params?: Params, skip = false): void {
    if (skip) return;

    const tree = this.router.parseUrl(url);
    tree.queryParams = {};
    const cleanUrl = tree.toString();

    if (this.redirectUrl || url === '/' || url.includes('%2')) {
      return;
    }

    this.redirectUrl = cleanUrl;
    this.redirectParams = params;

    this.addRedirectUrlAsQueryParam();
  }

  /**
   * Attaches redirect url to the param
   *
   */
  public addRedirectUrlAsQueryParam(): void {
    if (!this.redirectUrl || this.redirectUrl === '/' || this.redirectUrl.includes('%')) {
      return;
    }

    this.router.navigate(['../'], {
      queryParams: {
        redirectUrl: this.redirectUrl,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Redirect user to the desired location
   *
   */
  public redirect(): void {
    if (!this.redirectUrl) {
      this.router.navigate([this.defaultRedirectUrl]);

      return;
    }

    this.router
      .navigate([this.redirectUrl.split('?')[0]], {
        queryParams: this.redirectParams,
        queryParamsHandling: 'merge',
      })
      .finally(() => {
        this.redirectParams = undefined;
        this.redirectUrl = null;
      });
  }
}
