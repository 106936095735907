import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ComponentStore } from '@ngrx/component-store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslocoApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LanguageItem } from '@web/web/shared/ui/language-switch-new';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { enumToArray } from '@web/web/shared/util/function';
import { Observable, ReplaySubject, distinctUntilChanged, tap } from 'rxjs';

export interface LanguageState {
  activeLanguage: TranslocoApi.Locale;
  languagesNew: LanguageItem[];
}

@Injectable({
  providedIn: 'root',
})
export class LanguageSwitchService extends ComponentStore<LanguageState> {
  private readonly activeLanguageKey = 'preferred_language';

  private get activeLanguageFromLocalStorage(): TranslocoApi.Locale | null {
    if (isPlatformBrowser(this._platformId)) {
      const langFromStorage = localStorage.getItem(this.activeLanguageKey);

      if (!langFromStorage) {
        return null;
      }

      return langFromStorage as TranslocoApi.Locale;
    }

    return null;
  }

  /**
   * Takes only the first string of the whole language code
   * Format of language code looks like this en-US
   *
   * @private
   */
  private get browserLanguageAsTranslocoLocale(): TranslocoApi.Locale {
    if (isPlatformBrowser(this._platformId)) {
      const supportedLanguages: TranslocoApi.Locale[] = enumToArray(TranslocoApi.Locale);
      const language = navigator.language.split('-')[0].toUpperCase() as TranslocoApi.Locale;

      if (supportedLanguages.includes(language)) {
        return language;
      }

      return TranslocoApi.Locale.DE;
    }

    return TranslocoApi.Locale.DE;
  }

  public readonly activeLanguage$ = new ReplaySubject<TranslocoApi.Locale>(1);

  public readonly vm$: Observable<LanguageState> = this.select(state => ({
    activeLanguage: state.activeLanguage,
    languagesNew: state.languagesNew,
  })).pipe(
    distinctUntilChanged(),
    tap(({ activeLanguage }) => this.activeLanguage$.next(activeLanguage)),
  );

  constructor(
    private readonly translocoService: TranslocoService,
    @Inject(PLATFORM_ID) private _platformId: object,
  ) {
    super({
      languagesNew: [
        {
          title: 'English',
          isSelected: true,
          locale: TranslocoApi.Locale.EN,
          imageSrc: '/assets/image/country/rectangular/uk.svg',
        },
        {
          title: 'German',
          isSelected: false,
          locale: TranslocoApi.Locale.DE,
          imageSrc: '/assets/image/country/rectangular/de.svg',
        },
        {
          title: 'Spanish',
          isSelected: true,
          locale: TranslocoApi.Locale.ES,
          imageSrc: '/assets/image/country/rectangular/es.svg',
        },
        {
          title: 'French',
          isSelected: false,
          locale: TranslocoApi.Locale.FR,
          imageSrc: '/assets/image/country/rectangular/fr.svg',
        },
        {
          title: 'Croatian',
          isSelected: true,
          locale: TranslocoApi.Locale.HR,
          imageSrc: '/assets/image/country/rectangular/hr.svg',
        },
        {
          title: 'Russian',
          isSelected: false,
          locale: TranslocoApi.Locale.RU,
          imageSrc: '/assets/image/country/rectangular/ru.svg',
        },
        {
          title: 'Romanian',
          isSelected: true,
          locale: TranslocoApi.Locale.RO,
          imageSrc: '/assets/image/country/rectangular/ro.svg',
        },
        {
          title: 'Polish',
          isSelected: true,
          locale: TranslocoApi.Locale.PL,
          imageSrc: '/assets/image/country/rectangular/pl.svg',
        },
        {
          title: 'Italian',
          isSelected: false,
          locale: TranslocoApi.Locale.IT,
          imageSrc: '/assets/image/country/rectangular/it.svg',
        },
        {
          title: 'Turkish',
          isSelected: false,
          locale: TranslocoApi.Locale.TR,
          imageSrc: '/assets/image/country/rectangular/tr.svg',
        },
        {
          title: 'Slovak',
          isSelected: false,
          locale: TranslocoApi.Locale.SK,
          imageSrc: '/assets/image/country/rectangular/sk.svg',
        },
        {
          title: 'Czech',
          isSelected: false,
          locale: TranslocoApi.Locale.CS,
          imageSrc: '/assets/image/country/rectangular/cz.svg',
        },
        {
          title: 'Ukrainian',
          isSelected: false,
          locale: TranslocoApi.Locale.UK,
          imageSrc: '/assets/image/country/rectangular/ukr.svg',
        },
      ],
      activeLanguage: TranslocoApi.Locale.EN,
    });

    this.translocoService.setFallbackLangForMissingTranslation({ fallbackLang: TranslocoApi.Locale.EN.toLowerCase() });

    this.init();
  }

  public getSelectedLanguage(): TranslocoApi.Locale {
    const { activeLanguage } = this.get();

    return activeLanguage ?? TranslocoApi.Locale.EN;
  }

  public setSelectedLanguage(selectedLanguage: TranslocoApi.Locale): void {
    if (!selectedLanguage) {
      return;
    }

    const { languagesNew } = this.get();

    const updatedLanguages = languagesNew.slice();
    updatedLanguages.forEach(language => (language.isSelected = language.locale === selectedLanguage));

    this.patchState({ languagesNew: updatedLanguages });
    this.patchState({ activeLanguage: selectedLanguage });

    this.translocoService.setActiveLang(selectedLanguage.toLowerCase());

    this.saveLangToLocalStorage(selectedLanguage);
  }

  public init(): void {
    const languageFromLocalStorage = this.activeLanguageFromLocalStorage;

    if (languageFromLocalStorage) {
      //  1. Active language in storage already
      this.updateLanguageByLocale(languageFromLocalStorage);

      return;
    }

    //  2. If no active language in storage, get it from the local machine browser's settings
    this.updateLanguageByLocale(this.browserLanguageAsTranslocoLocale);
  }

  private saveLangToLocalStorage(locale: TranslocoApi.Locale): void {
    if (isPlatformBrowser(this._platformId)) {
      localStorage.setItem(this.activeLanguageKey, locale);
    }
  }

  private updateLanguageByLocale(locale: TranslocoApi.Locale): void {
    const { languagesNew } = this.get();

    const updatedLanguages = languagesNew.slice();
    updatedLanguages.forEach(language => (language.isSelected = language.locale === locale));

    this.patchState({ languagesNew: updatedLanguages, activeLanguage: locale });

    this.translocoService.setActiveLang(locale.toLowerCase());
    this.saveLangToLocalStorage(locale);
  }
}
