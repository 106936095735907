import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';

@Component({
  selector: 'wh-s-modal',
  template: `
    <ng-container *ngIf="open">
      <div
        class="separate-backdrop"
        [ngClass]="open ? 'open' : 'closed'"
        (click)="closeModal($event)"
        (scroll)="onScroll($event)"
        (wheel)="onScroll($event)"
      ></div>

      <div [ngClass]="modalClasses">
        <div class="wh-modal--header">
          <div class="wh-modal--header--title">
            <ng-content select=".wh-modal-title"></ng-content>
          </div>

          <i-feather class="wh-modal--header--close" (click)="closeModal($event)" name="x"></i-feather>
        </div>

        <div class="wh-modal--body">
          <ng-content select=".wh-modal-body"></ng-content>
        </div>

        <div class="wh-modal--actions">
          <ng-content select=".wh-modal-actions"></ng-content>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./modal.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class ModalComponent {
  /* Icons */
  public faTimes = faTimes;
  /**
   * Whether the modal is open or not
   */
  @Input()
  public open = false;

  /**
   * How large should the modal be?
   */
  @Input()
  public size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

  /**
   * position of modal EDS
   */
  @Input()
  public position: 'bottom' | 'absolute' | 'top' = 'top';
  /**
   * Close handler
   */
  @Output()
  public modalCloseAction = new EventEmitter<Event>();

  public get modalClasses(): string[] {
    return ['wh-modal', `wh-modal--${this.size}`, this.open ? 'open' : '', `wh-modal--${this.position}`];
  }

  constructor(public readonly loadingViewModel: LoadingViewModel) {}

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public closeModal($event: any): void {
    if (this.loadingViewModel.isLoading) {
      return;
    }

    this.open = false;
    this.modalCloseAction.emit($event);
  }

  /**
   * While backdrop is active, prevent scroll on the parent elements
   *
   * @param event
   */
  public onScroll(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
