<div class="language-wrapper">
  <div class="img-wrapper">
    <img [src]="selectedLanguage?.imageSrc" alt="" />
  </div>

  <div class="title-wrapper">
    <p>{{ selectedLanguage?.title }}</p>
  </div>
</div>

<div *ngIf="isFocused" @enterLeave class="languages-list-container">
  <div
    *ngFor="let language of _languages"
    class="language-row"
    (click)="languageClicked.emit(language.locale)"
    (keydown.enter)="languageClicked.emit(language.locale)"
    tabindex="0"
    role="button"
  >
    <div class="img-wrapper">
      <img [src]="language.imageSrc" alt="" />
    </div>

    <div class="title-wrapper">
      <p>{{ language.title }}</p>
    </div>
  </div>
</div>
