import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export abstract class AuthLocalStorageService<T> {
  private loginDataKey = 'login_data_key';

  constructor(@Inject(PLATFORM_ID) private _platformId: object) {}

  public storeLoginData(loginData: T): void {
    if (isPlatformBrowser(this._platformId)) {
      localStorage.setItem(this.loginDataKey, JSON.stringify(loginData));
    }
  }
  public clearData(): void {
    if (isPlatformBrowser(this._platformId)) {
      localStorage.removeItem(this.loginDataKey);
    }
  }

  public getLoginData(): T | undefined {
    if (isPlatformBrowser(this._platformId)) {
      const loginData = localStorage.getItem(this.loginDataKey);

      if (!loginData) {
        return undefined;
      }

      return JSON.parse(loginData);
    }

    return undefined;
  }
}
