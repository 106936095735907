import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CompensationApi, JobApi } from '@web/shared/data-access/model';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { FeatherIconType } from '@web/web/shared/ui/feather-icon';
import { JobDetailInfoCardComponent } from '@web/web/shared/ui/job-detail-info-card';
import { SquaredChipComponent } from '@web/web/shared/ui/squared-chip';
import { FormatCompensationShortPipe } from '@web/web/shared/util/pipes';

interface ChipItem {
  label: string;
  backgroundColor?: string;
  fontColor?: string;
  icon?: FeatherIconType;
}

@Component({
  selector: 'wh-s-profile-detail-job-list-item',
  imports: [CommonModule, JobDetailInfoCardComponent, SquaredChipComponent, WebCoreI18nModule],
  providers: [FormatCompensationShortPipe],
  template: `
    <wh-s-job-detail-info-card
      [title]="title"
      [ctaTitle]="'shared.ui.job-detail-info-card.view-details' | transloco"
      (ctaClicked)="ctaClick.emit()"
    >
      <div slot="content">
        <wh-s-squared-chip
          *ngFor="let chip of chipList"
          [icon]="chip.icon"
          [label]="chip.label"
          [fontColor]="chip.fontColor"
          [backgroundColor]="chip.backgroundColor"
        ></wh-s-squared-chip>
      </div>
    </wh-s-job-detail-info-card>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;

      :host {
        display: inline-block;
        height: max-content;
        min-width: 23rem;
        width: 23rem;
        margin-inline: 1rem;

        @include mx.df-screen(mobile) {
          min-width: 90%;
          width: 100%;
        }
      }
    `,
  ],
})
export class ProfileDetailJobListItemComponent implements OnInit {
  @Input({
    required: true,
  })
  public compensations: CompensationApi.Compensation[];

  @Input({
    required: true,
  })
  public employmentTypes: JobApi.EmploymentType[];

  @Input({
    required: true,
  })
  public location: string;

  @Input({
    required: true,
  })
  public title: string;

  @Output()
  public ctaClick = new EventEmitter();

  public chipList: ChipItem[] = [];

  constructor(
    private readonly formatCurrencyShortPipe: FormatCompensationShortPipe,
    private readonly translocoService: TranslocoService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  // TODO: use compensation pipes and employmentType pipes directly in html because
  //  .transform() in .ts files doesnt work when larger data needs to be processed, like reading from transloco files
  //  (separate chips by optional enum field for compensations and jobTypes)
  public ngOnInit(): void {
    setTimeout(() => {
      this.chipList = [];
      this.parseChips();
      this.cdr.detectChanges();
    }, 0);
  }

  private parseChips(): void {
    this.chipList.push(
      //  Convert compensation items
      ...this.compensations
        .filter(compensationItem => compensationItem.amountFrom != null || compensationItem.amountTo != null)
        .map(compensationItem => {
          return {
            label: this.formatCurrencyShortPipe.transform(compensationItem),
            backgroundColor: '#101010',
            fontColor: '#B4B4B4',
          };
        }),
      //  Convert employment type items
      ...this.employmentTypes.map(employmentType => ({
        label: this.translocoService.translate(JobApi.employmentTypeToHumanReadableValue(employmentType)),
        backgroundColor: '#EBEBEB',
      })),
      //  Convert location
      {
        label: `${this.location}`,
        backgroundColor: '#EBEBEB',
        icon: 'map-pin',
      },
    );
  }
}
