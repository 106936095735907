import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslocoApi } from '@web/shared/data-access/model';
import { enterLeave } from '@web/web/shared/ui/animation';
import { BackdropComponent } from '@web/web/shared/ui/backdrop';
import { LanguageItem } from './language-switch-new.interface';

@Component({
  selector: 'wh-language-switch-new',
  imports: [CommonModule, BackdropComponent],
  templateUrl: './language-switch-new.component.html',
  styleUrls: ['./language-switch-new.component.scss'],
  animations: [enterLeave],
})
export class LanguageSwitchNewComponent {
  @Input()
  public set languages(languages: LanguageItem[]) {
    this._languages = languages;

    languages.forEach(language => (language.isSelected ? (this.selectedLanguage = language) : null));
  }
  public _languages: LanguageItem[] = [];

  @Output()
  public languageClicked = new EventEmitter<TranslocoApi.Locale>();

  @HostBinding('class.focused')
  public isFocused = false;

  public selectedLanguage: LanguageItem;

  @HostListener('click', ['$event'])
  public languageSwitchClicked(mouseEvent: MouseEvent): void {
    this.isFocused = !this.isFocused;

    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
  }

  @HostListener('document:click')
  public documentClick(): void {
    if (!this.isFocused) {
      return;
    }

    this.isFocused = !this.isFocused;
  }
}
