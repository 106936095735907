import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CompensationApi } from '@web/shared/data-access/model';
import convertCurrencyToHumanReadableString = CompensationApi.convertCurrencyToHumanReadableString;

@Pipe({
  name: 'formatCompensationShort',
  standalone: true,
})
export class FormatCompensationShortPipe implements PipeTransform {
  private readonly translocoService = inject(TranslocoService);

  public transform(compensation: CompensationApi.Compensation): string {
    switch (compensation.compensationType) {
      case CompensationApi.CompensationType.SALARY:
        return (
          `${this.translocoService.translate('shared.ui.compensation-types.SALARY')} ${compensation.amountFrom ? compensation.amountFrom : compensation.amountTo} ${convertCurrencyToHumanReadableString(compensation.currency)} / ` +
          this.translocoService.translate(`shared.ui.intervals.${compensation.interval}`)
        );
      case CompensationApi.CompensationType.SALARY_RANGE:
        return (
          `${compensation.amountTo}${convertCurrencyToHumanReadableString(compensation.currency)} - ${
            compensation.amountFrom
          } ${convertCurrencyToHumanReadableString(compensation.currency)} / ` +
          this.translocoService.translate(`shared.ui.intervals.${compensation.interval}`)
        );
      case CompensationApi.CompensationType.BONUS:
        return (
          `${compensation.amountFrom}${convertCurrencyToHumanReadableString(compensation.currency)} / ` +
          this.translocoService.translate(`shared.ui.bonus-types.${compensation.bonusType}`)
        );
    }
  }
}
