import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import {
  ApplicantApi,
  ApplicantApprenticeshipApi,
  ApplicantTrackingSystemApi,
  ApplicantWorkExperienceApi,
  DriverLicenseApi,
  HereMapsApi,
  JobTypeApi,
  PosthogApi,
  TranslocoApi,
} from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicantAssessmentViewModel } from '@web/web/applicant/domain/job/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AimwelService } from '@web/web/shared/data-access/aimwel';
import { AlertService } from '@web/web/shared/data-access/alert';
import { ApplicantApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { LanguageSwitchService } from '@web/web/shared/data-access/language';
import { PosthogService } from 'posthog';
import { EMPTY, Observable, catchError, concatMap, of, take, tap } from 'rxjs';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';
import { ApplicantAuthViewModel } from './applicant-auth.viewmodel';
import DriverLicenseType = DriverLicenseApi.DriverLicenseType;
import Source = ApplicantTrackingSystemApi.Source;
import DegreeApprenticeshipRegister = ApplicantApprenticeshipApi.DegreeApprenticeshipRegister;
import DegreeApprenticeship = ApplicantApprenticeshipApi.DegreeApprenticeship;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let fbq: any;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let gtag: any;

export interface RegisterState {
  apprenticeship?: ApplicantApprenticeshipApi.Apprenticeship | undefined;
  apprenticeshipDegreeItems: ApplicantApprenticeshipApi.DegreeItem[];
  selectedDegree?: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister;
  selectedJobType?: JobTypeApi.JobType;
  selectedJobTypeId?: string;
  jobTypeSelected: boolean;
  workExperience?: ApplicantWorkExperienceApi.WorkExperience | undefined;
  workExperienceSelected?: boolean;
  workExpSelectedJobType?: JobTypeApi.JobType;
  workExpSelectedJobTypeId?: string;
  workExpJobTypeSelected: boolean;
  addressData?: Partial<HereMapsApi.AddressData> | undefined;
  selectedDriverLicenses?: DriverLicenseApi.DriverLicenseApplicant[];
  selectedLicensesStr?: string[];
  languages?: TranslocoApi.Language[];
  englishLangLevel?: TranslocoApi.LanguageLevel;
  germanLangLevel?: TranslocoApi.LanguageLevel;
  germanViewActive: boolean;
  languagesSelected: boolean;
  steps: string[];
  activeStep: ApplicantApi.RegisterStep;
  activeStepIndexToNumber: number;
  externalApplicationSuccess: boolean;
  isAlreadyInUse: boolean;
  isOtherError: boolean;
  emailAlreadyExists: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RegisterViewModel extends ComponentStore<RegisterState> {
  public vm$: Observable<RegisterState> = this.select(state => ({
    apprenticeship: state.apprenticeship,
    apprenticeshipDegreeItems: state.apprenticeshipDegreeItems,
    selectedDegree: state.selectedDegree,
    selectedJobType: state.selectedJobType,
    selectedJobTypeId: state.selectedJobTypeId,
    jobTypeSelected: state.jobTypeSelected,
    workExperience: state.workExperience,
    workExperienceSelected: state.workExperienceSelected,
    workExpSelectedJobType: state.workExpSelectedJobType,
    workExpSelectedJobTypeId: state.workExpSelectedJobTypeId,
    workExpJobTypeSelected: state.workExpJobTypeSelected,
    addressData: state.addressData,
    selectedLicensesStr: state.selectedLicensesStr,
    englishLangLevel: state.englishLangLevel,
    germanLangLevel: state.germanLangLevel,
    germanViewActive: state.germanViewActive,
    languagesSelected: state.languagesSelected,
    steps: state.steps,
    activeStep: state.activeStep,
    activeStepIndexToNumber:
      state.activeStep === ApplicantApi.RegisterStep.APPRENTICESHIP ||
      state.activeStep === ApplicantApi.RegisterStep.WORK_EXPERIENCE
        ? 0
        : state.activeStep === ApplicantApi.RegisterStep.DRIVER_LICENSE
          ? 1
          : state.activeStep === ApplicantApi.RegisterStep.LANGUAGE
            ? 2
            : state.activeStep === ApplicantApi.RegisterStep.ADDRESS
              ? 3
              : 4,
    externalApplicationSuccess: state.externalApplicationSuccess,
    isAlreadyInUse: state.isAlreadyInUse,
    isOtherError: state.isOtherError,
    emailAlreadyExists: state.emailAlreadyExists,
  }));

  constructor(
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    private readonly applicantAssessmentViewModel: ApplicantAssessmentViewModel,
    private readonly router: Router,
    private readonly applicantApiService: ApplicantApiService,
    private readonly jobApplicationApiService: JobApplicationApiService,
    private readonly utmLocalStorageService: UtmLocalStorageService,
    private readonly languageSwitchService: LanguageSwitchService,
    private readonly aimwelService: AimwelService,
    private readonly alertService: AlertService,
    private readonly applicantPosthogService: PosthogService,
  ) {
    super({
      steps: [],
      activeStep: ApplicantApi.RegisterStep.APPRENTICESHIP,
      activeStepIndexToNumber: 0,
      apprenticeshipDegreeItems: [
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.VOCATIONAL_TRAINING_CURRENT,
          icon: 'book-open',
        },
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.VOCATIONAL_TRAINING_COMPLETED,
          icon: 'grid',
        },
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.TECHNICIAN_MASTER_CRAFTSMAN,
          icon: 'grid',
        },
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.NO,
          icon: 'x',
        },
      ],
      jobTypeSelected: false,
      workExpJobTypeSelected: false,
      germanViewActive: false,
      languagesSelected: false,
      selectedLicensesStr: [],
      externalApplicationSuccess: false,
      isAlreadyInUse: false,
      isOtherError: false,
      emailAlreadyExists: false,
    });

    this.initSteps();
  }

  public initSteps(): void {
    const steps = ['Apprenticeship', 'Driver License', 'Language', 'Address'];

    this.patchState({ steps });
  }

  public setAddressData(addressData: Partial<HereMapsApi.AddressData> | undefined): void {
    this.patchState({ addressData });
  }

  public step(activeStep: ApplicantApi.RegisterStep): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_STEP_CHANGE, { step: activeStep });

    this.patchState({ activeStep });
  }

  public setApprenticeshipDegree(degree: ApplicantApprenticeshipApi.DegreeItem): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_APPRENTICESHIP, {
      option: degree.degree,
    });

    if (degree.degree === DegreeApprenticeshipRegister.NO) {
      this.noApprenticeshipCase();

      return;
    }
    this.patchState({ selectedDegree: degree.degree });
  }

  public getApprenticeshipDegree(): ApplicantApprenticeshipApi.DegreeApprenticeshipRegister | undefined {
    return this.get().selectedDegree;
  }

  public setJobTypeSelected(jobType: JobTypeApi.JobType | undefined): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_APPRENTICESHIP_JOB_TYPE, {
      jobType: jobType,
    });

    if (jobType) {
      this.patchState({ jobTypeSelected: true });
      this.patchState({ selectedJobType: jobType });
      this.patchState({ selectedJobTypeId: jobType.id });

      return;
    }
    this.patchState({ jobTypeSelected: false });
  }

  public getLocalStorageJobType(): string | undefined {
    const applicantLS = localStorage.getItem('create_account_data_key');
    const preferredLanguage = localStorage.getItem('preferred_language');
    if (applicantLS) {
      const parsedApplicant = JSON.parse(applicantLS);

      if (
        parsedApplicant.apprenticeShip &&
        parsedApplicant.apprenticeShip.jobType &&
        parsedApplicant.apprenticeShip.jobType.textVariants
      ) {
        if (
          parsedApplicant.apprenticeShip.jobType.textVariants.find(
            (variant: { locale: string }) => variant.locale == preferredLanguage,
          )?.value
        )
          return parsedApplicant.apprenticeShip.jobType.textVariants.find(
            (variant: { locale: string }) => variant.locale == preferredLanguage,
          )?.value;
        else if (
          parsedApplicant.apprenticeShip.jobType.textVariants.find(
            (variant: { locale: string }) => variant.locale == TranslocoApi.Locale.DE,
          )?.value
        )
          return parsedApplicant.apprenticeShip.jobType.textVariants.find(
            (variant: { locale: string }) => variant.locale == TranslocoApi.Locale.DE,
          )?.value;

        return undefined;
      }
    }

    return;
  }

  public apprenticeShipStepCompleted(): void {
    this.patchState({
      apprenticeship: {
        ...this.get().apprenticeship,
        degree: this.get().selectedDegree as unknown as DegreeApprenticeship,
        jobTypeId: this.get()?.selectedJobTypeId,
        jobType: this.get()?.selectedJobType,
      },
    });
    if (this.get().apprenticeship) {
      this.getLocalStorageJobType();
      this.applicantAuthViewModel.storeAccountData({
        apprenticeShip: <ApplicantApprenticeshipApi.Apprenticeship>this.get().apprenticeship,
      });
    }
    this.step(ApplicantApi.RegisterStep.DRIVER_LICENSE);
  }

  public noApprenticeshipCase(): void {
    this.patchState({ apprenticeship: undefined });
    this.applicantAuthViewModel.storeAccountData({
      apprenticeShip: <ApplicantApprenticeshipApi.Apprenticeship>this.get().apprenticeship,
    });
    this.patchState({ jobTypeSelected: false });
    this.patchState({ selectedJobTypeId: undefined });
    this.patchState({ selectedDegree: DegreeApprenticeshipRegister.NO });
    this.patchState({ workExperienceSelected: undefined });
    this.patchState({ steps: ['Work Experience', 'Driver License', 'Language', 'Address'] });
    this.step(ApplicantApi.RegisterStep.WORK_EXPERIENCE);
  }

  public setWorkExperience(hasWorkExperience: boolean): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_WORK_EXPERIENCE, {
      hasWorkExperience,
    });

    if (hasWorkExperience) {
      this.patchState({ workExperienceSelected: true });
    } else {
      this.noWorkExperienceCase();
    }
  }

  public setWorkExpJobTypeSelected(jobType: JobTypeApi.JobType | undefined): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_WORK_EXPERIENCE_JOB_TYPE, {
      jobType: jobType,
    });

    if (jobType) {
      this.patchState({ workExpJobTypeSelected: true });
      this.patchState({ workExpSelectedJobType: jobType });
      this.patchState({ workExpSelectedJobTypeId: jobType.id });

      return;
    }
    this.patchState({ workExpJobTypeSelected: false });
  }

  public getLocalStorageWorkExpJobType(): string | undefined {
    const applicantLS = localStorage.getItem('create_account_data_key');
    const preferredLanguage = localStorage.getItem('preferred_language');
    if (applicantLS) {
      const parsedApplicant = JSON.parse(applicantLS);

      if (
        parsedApplicant.workExperience &&
        parsedApplicant.workExperience.jobType &&
        parsedApplicant.workExperience.jobType.textVariants
      ) {
        return (
          parsedApplicant.workExperience.jobType.textVariants.find(
            (variant: { locale: string }) => variant.locale == preferredLanguage,
          )?.value ??
          parsedApplicant.workExperience.jobType.textVariants.find(
            (variant: { locale: string }) => variant.locale == TranslocoApi.Locale.DE,
          )?.value ??
          undefined
        );
      }
    }

    return;
  }

  public workExperienceStepCompleted(): void {
    this.patchState({
      workExperience: {
        ...this.get().workExperience,
        jobTypeId: this.get()?.workExpSelectedJobTypeId,
        jobType: this.get()?.workExpSelectedJobType,
      },
    });
    if (this.get().workExperience) {
      this.getLocalStorageWorkExpJobType();
      this.applicantAuthViewModel.storeAccountData({
        workExperience: <ApplicantWorkExperienceApi.WorkExperience>this.get().workExperience,
      });
    }
    this.patchState({ workExperienceSelected: false });
    this.step(ApplicantApi.RegisterStep.DRIVER_LICENSE);
  }

  public noWorkExperienceCase(): void {
    this.patchState({ workExperience: undefined });
    this.applicantAuthViewModel.storeAccountData({
      workExperience: <ApplicantWorkExperienceApi.WorkExperience>this.get().workExperience,
    });
    this.patchState({ workExpJobTypeSelected: !this.get().workExpJobTypeSelected });
    this.patchState({ workExpSelectedJobTypeId: undefined });
    this.patchState({ steps: ['Work Experience', 'Driver License', 'Language', 'Address'] });
    this.step(ApplicantApi.RegisterStep.DRIVER_LICENSE);
  }

  public backFromDriverLicenseStep(): void {
    const applicantLS = localStorage.getItem('create_account_data_key');
    if (applicantLS) {
      const parsedApplicant = JSON.parse(applicantLS);
      if (parsedApplicant.workExperience) {
        this.step(ApplicantApi.RegisterStep.WORK_EXPERIENCE);
      } else {
        this.step(ApplicantApi.RegisterStep.APPRENTICESHIP);
      }
    }
  }

  public setSelectedDriverLicenses(licensesStr: string[]): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_DRIVER_LICENSE, {
      licenses: licensesStr,
    });

    this.patchState({ selectedLicensesStr: licensesStr });

    const checkForNoLicense = licensesStr.find(dlt => dlt === DriverLicenseType.NO);

    /* Logic that makes sure if NO exists and is last element in licensesStr, we remove all the others,
    and if NO exists, but it's not the last one, than NO is removed from array */
    const lastElement: string = licensesStr[licensesStr.length - 1];

    if (checkForNoLicense) {
      if (lastElement === DriverLicenseType.NO) {
        this.patchState({ selectedLicensesStr: [DriverLicenseType.NO] });
      } else {
        licensesStr = licensesStr.filter(dlt => dlt !== DriverLicenseType.NO);
        this.patchState({ selectedLicensesStr: licensesStr });
      }
    }

    /* Iterate over licensesStr array and assign each string to the corresponding index in selectedLicenses array,
       then add every licenseType in array, or empty an array if 'no_license' is inside */
    const selectedLicenses: DriverLicenseApi.DriverLicenseApplicant[] = licensesStr.map(licenseStr => ({
      licenseType: licenseStr as DriverLicenseType,
    }));

    this.patchState({ selectedDriverLicenses: selectedLicenses });
  }

  public checkIfLicenseSelected(): boolean {
    const selectedLicenses = this.get().selectedDriverLicenses;

    if (selectedLicenses) {
      return selectedLicenses.length > 0;
    }

    return false;
  }

  public goToDriverLicense(): void {
    this.patchState({ germanViewActive: false });
    this.step(ApplicantApi.RegisterStep.DRIVER_LICENSE);
  }

  public addDriverLicense(): void {
    /* If DriverLicenseType.NO exists in selectedDriverLicenses list, empty the list */
    const hasNoLicense =
      this.get().selectedDriverLicenses?.some(license => license.licenseType === DriverLicenseType.NO) ?? false;

    if (hasNoLicense) {
      this.patchState({ selectedDriverLicenses: [] });
    }

    this.applicantAuthViewModel.storeAccountData({
      driverLicenses: <DriverLicenseApi.DriverLicense[]>this.get().selectedDriverLicenses,
    });
    this.step(ApplicantApi.RegisterStep.LANGUAGE);
  }

  public onEnglishLevelSelection(englishLevel: TranslocoApi.LanguageLevel): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_ENGLISH_LEVEL, {
      level: englishLevel,
    });

    this.patchState({ englishLangLevel: englishLevel });
    setTimeout(() => {
      this.patchState({ germanViewActive: true });
    }, 500);
  }

  public onGermanLevelSelection(germanLevel: TranslocoApi.LanguageLevel): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_SET_GERMAN_LEVEL, {
      level: germanLevel,
    });

    this.patchState({ germanLangLevel: germanLevel });
    this.patchState({ languagesSelected: true });

    this.addLanguage();
  }

  public getEnglishLevel(): TranslocoApi.LanguageLevel | undefined {
    const englishLevel = this.get().englishLangLevel;
    if (englishLevel) {
      return englishLevel;
    }

    return undefined;
  }

  public getGermanLevel(): TranslocoApi.LanguageLevel | undefined {
    const germanLevel = this.get().germanLangLevel;
    if (germanLevel) {
      return germanLevel;
    }

    return undefined;
  }

  public addLanguage(): void {
    const englishLang: TranslocoApi.Language = {
      language: TranslocoApi.OfficialLanguage.ENGLISH,
      level: this.get().englishLangLevel,
    };
    const germanLang: TranslocoApi.Language = {
      language: TranslocoApi.OfficialLanguage.GERMAN,
      level: this.get().germanLangLevel,
    };

    this.patchState({ languages: [englishLang, germanLang] });

    this.applicantAuthViewModel.storeAccountData({
      languages: <TranslocoApi.Language[]>this.get().languages,
    });

    this.step(ApplicantApi.RegisterStep.ADDRESS);
  }

  public addAddress(): void {
    if (this.get().addressData)
      this.applicantAuthViewModel.storeAccountData({
        address: <HereMapsApi.AddressData>this.get().addressData,
      });
    // this.router.navigate([`/auth/register/phone-verification`]);
    this.step(ApplicantApi.RegisterStep.PHONE_REGISTRATION);
  }

  public phoneConfirmation(): void {
    this.step(ApplicantApi.RegisterStep.PHONE_CONFIRMATION);
  }

  public createAccount(): void {
    this.step(ApplicantApi.RegisterStep.CREATE_ACCOUNT_STEP_1);
  }

  public successStep(): void {
    this.step(ApplicantApi.RegisterStep.SUCCESS);
  }

  public backToLogin(): void {
    this.router.navigate([`/auth/login`], { queryParamsHandling: 'merge' });
    localStorage.removeItem('create_account_data_key');
  }

  public processRegistration(applicantData: ApplicantApi.Applicant): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_CREATE_ACCOUNT, {
      applicant: applicantData,
      method: 'email',
    });

    const applicant: ApplicantApi.RegisterApplicant = {
      ...(<ApplicantApi.RegisterApplicant>JSON.parse(localStorage.getItem('create_account_data_key') ?? '')),
      ...applicantData,
    };

    applicant.communicationLanguage = this.languageSwitchService.getSelectedLanguage();

    // TODO: refactor this
    applicant.zapier = applicant.zapier ? JSON.parse(applicant.zapier as never) : false;

    this.applicantApiService
      .createAccount({ ...applicant })
      .pipe(
        take(1),
        concatMap(loginData => {
          this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_FINISH);

          if (loginData) {
            this.applicantAuthViewModel.processRegistration(loginData);
          }

          return of(loginData);
        }),
        catchError(error => {
          if (error.statusCode === 409 || error.error?.statusCode === 409) {
            this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_EMAIL_IN_USE_ERROR, {
              email: applicant.email,
            });

            this.patchState({ isAlreadyInUse: true });
          } else {
            this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_OTHER_ERROR);

            this.patchState({ isOtherError: true });
          }

          return EMPTY;
        }),
        take(1),
        concatMap(loginData => {
          if (loginData && loginData.id) {
            return this.applicantApiService.addAdditionalRegisterData(applicant, loginData.id);
          }

          return EMPTY;
        }),
        take(1),
        concatMap(() => {
          return this.handleExternalApplicationData();
        }),
        take(1),
        concatMap(jobApplication => {
          const externalApplicationData = this.applicantAuthViewModel.getExternalApplicationData();

          if (
            jobApplication &&
            jobApplication.currentStep.assessment &&
            externalApplicationData?.isAssessmentPassed === undefined
          ) {
            this.applicantAssessmentViewModel.setAssessmentId(jobApplication.currentStep.assessment.id);
            this.applicantAssessmentViewModel.getAssessmentDetails();
            this.step(ApplicantApi.RegisterStep.ASSESSMENT);
          } else {
            this.step(ApplicantApi.RegisterStep.SUCCESS);
          }

          return of(undefined);
        }),
        tap(() => {
          this.applicantAuthViewModel.removeAccountData();
          this.applicantAuthViewModel.removeExternalApplicationData();
          this.utmLocalStorageService.removeUtmData();
        }),
      )
      .subscribe();
  }

  public checkIfEmailAlreadyExists(email: string): Observable<boolean> {
    return this.applicantApiService.checkIfEmailAlreadyExists(email).pipe(
      take(1),
      tap(emailAlreadyExists => this.patchState({ emailAlreadyExists })),
    );
  }

  public resetEmailAlreadyExistsState(): void {
    this.patchState({ emailAlreadyExists: false });
  }

  public registerWithGoogle(token: string): void {
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_GOOGLE);
    this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_CREATE_ACCOUNT, {
      method: 'google',
    });

    const applicant: ApplicantApi.RegisterApplicant = {
      ...(<ApplicantApi.RegisterApplicant>JSON.parse(localStorage.getItem('create_account_data_key') ?? '{}')),
    };

    applicant.communicationLanguage = this.languageSwitchService.getSelectedLanguage();

    // TODO: refactor this
    applicant.zapier = applicant.zapier ? JSON.parse(applicant.zapier as never) : false;

    this.applicantApiService
      .registerWithGoogle(token, applicant)
      .pipe(
        take(1),
        concatMap(data => {
          this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_GOOGLE_SUCCESS);
          this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_FINISH);

          this.applicantAuthViewModel.processRegistration(data);

          return of(data);
        }),
        catchError(err => {
          this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_GOOGLE_ERROR);
          if (err.status === 409) {
            this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_GOOGLE_ACC_IN_USE, {
              email: applicant.email,
            });

            this.alertService.error(`core.auth.feature.login.google-register-acc-exists`);
          } else {
            this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.REGISTRATION_GOOGLE_PERMISSION_DENIED);

            this.alertService.error(`core.auth.feature.login.google-permission-denied-error-alert`);
          }

          this.patchState({ activeStep: ApplicantApi.RegisterStep.APPRENTICESHIP });
          this.applicantAuthViewModel.removeAccountData();

          return this.router.navigate(['/auth/login']);
        }),
        take(1),
        concatMap(() => {
          return this.handleExternalApplicationData();
        }),
        take(1),
        concatMap(jobApplication => {
          const externalApplicationData = this.applicantAuthViewModel.getExternalApplicationData();

          if (
            jobApplication &&
            jobApplication.currentStep.assessment &&
            externalApplicationData?.isAssessmentPassed === undefined
          ) {
            this.applicantAssessmentViewModel.setAssessmentId(jobApplication.currentStep.assessment.id);
            this.applicantAssessmentViewModel.getAssessmentDetails();
            this.step(ApplicantApi.RegisterStep.ASSESSMENT);
          } else {
            this.step(ApplicantApi.RegisterStep.SUCCESS);
          }

          return of(undefined);
        }),
        tap(() => {
          this.applicantAuthViewModel.removeAccountData();
          this.applicantAuthViewModel.removeExternalApplicationData();
          this.utmLocalStorageService.removeUtmData();
        }),
      )
      .subscribe();
  }

  public reset(): void {
    this.patchState({
      steps: [],
      activeStep: ApplicantApi.RegisterStep.APPRENTICESHIP,
      activeStepIndexToNumber: 0,
      apprenticeshipDegreeItems: [
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.VOCATIONAL_TRAINING_CURRENT,
          icon: 'book-open',
        },
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.VOCATIONAL_TRAINING_COMPLETED,
          icon: 'grid',
        },
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.TECHNICIAN_MASTER_CRAFTSMAN,
          icon: 'grid',
        },
        {
          degree: ApplicantApprenticeshipApi.DegreeApprenticeshipRegister.NO,
          icon: 'x',
        },
      ],
      jobTypeSelected: false,
      workExpJobTypeSelected: false,
      germanViewActive: false,
      languagesSelected: false,
      selectedLicensesStr: [],
      externalApplicationSuccess: false,
      isAlreadyInUse: false,
      isOtherError: false,
    });

    this.initSteps();
  }

  public handleExternalApplicationData(): Observable<ApplicantTrackingSystemApi.Application | undefined> {
    const externalApplicationData = this.applicantAuthViewModel.getExternalApplicationData();

    if (externalApplicationData && externalApplicationData.jobId) {
      this.patchState({ externalApplicationSuccess: true });

      this.applicantPosthogService.captureEvent(PosthogApi.PostHogEvent.APPLICANT_REGISTRATION_EXTERNAL, {
        jobId: externalApplicationData.jobId,
      });

      this.aimwelService.sendAimwelApplicationCompleteEvent();

      if (gtag) {
        gtag('event', 'conversion', {
          send_to: 'AW-375648535/tuxJCNr0r40CEJfij7MB',
        });
      }
      try {
        if (fbq) {
          fbq('track', 'Purchase', {
            value: 1.0,
            currency: 'EUR',
            content_ids: externalApplicationData.jobId ? [externalApplicationData.jobId] : [],
            content_type: 'product',
          });
          fbq('track', 'Lead', {
            content_ids: externalApplicationData.jobId ? [externalApplicationData.jobId] : [],
            value: 1.0,
            currency: 'EUR',
          });
        }
      } catch (e) {
        // check what's the best way to handle this case
        // eslint-disable-next-line no-console
        console.warn('fbq not defined');
      }

      return this.jobApplicationApiService.create({
        jobId: externalApplicationData.jobId,
        source: Source.EXTERNAL,
        utm: this.utmLocalStorageService.getUtmData(),
        zapier: externalApplicationData.zapier,
        isAssessmentPassed: externalApplicationData.isAssessmentPassed,
      });
    }

    this.step(ApplicantApi.RegisterStep.SUCCESS);

    return of(undefined);
  }
}
