import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { JobApplicationApi, RecruitmentProcessApi } from '@web/shared/data-access/model';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { ButtonModule } from '@web/web/shared/ui/button';
import { DropdownModule, DropdownOption } from '@web/web/shared/ui/dropdown';
import { InputModule } from '@web/web/shared/ui/input';
import { ModalModule } from '@web/web/shared/ui/modal';
import { TextAreaComponent } from '@web/web/shared/ui/text-area';
import { enumKeys } from '@web/web/shared/util/function';

@Component({
  selector: 'wh-s-app-abort-application',
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    DropdownModule,
    InputModule,
    FormsModule,
    TextAreaComponent,
    WebCoreI18nModule,
  ],
  templateUrl: './abort-application.component.html',
  styleUrls: ['./abort-application.component.scss'],
})
export class AbortApplicationComponent {
  @Input()
  public isOpened = false;

  @Input()
  public jobApplicationId: string;

  /**
   * Confirms application abort
   */
  @Output()
  public abortApplication: EventEmitter<JobApplicationApi.AbortApplication> =
    new EventEmitter<JobApplicationApi.AbortApplication>();

  /**
   * Cancels delete action
   */
  @Output()
  public cancelAction: EventEmitter<void> = new EventEmitter<void>();

  public abortApplicationItems: DropdownOption[] = enumKeys(RecruitmentProcessApi.ApplicantNoHireReason).map(
    reason => ({
      value: RecruitmentProcessApi.ApplicantNoHireReason[reason],
      label: this.translocoService.translate(`shared.ui.abort-application-reason.${reason}`),
    }),
  );

  public selectedReason: DropdownOption;

  public reasonExplanation: string;

  constructor(private readonly translocoService: TranslocoService) {}

  public onAbortApplication(): void {
    this.abortApplication.emit({
      jobApplicationId: this.jobApplicationId,
      noHireText: this.reasonExplanation,
      applicantNoFitReason: this.selectedReason.value,
    });
  }

  public setSelectedReasonExplanation($event: string): void {
    this.reasonExplanation = $event;
  }
}
