import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { AdminApi, ApplicantApi, AuthApi, EmployerApi, PromoterApi } from '@web/shared/data-access/model';
import { SquaredChipComponent } from '@web/web/shared/ui/squared-chip';

@Component({
  selector: 'wh-s-role-chip',
  imports: [CommonModule, TranslocoModule, SquaredChipComponent],
  template: ` <wh-s-squared-chip [label]="roleStr" backgroundColor="#141414" fontColor="#fbfbfb" /> `,
  styles: [``],
})
export class RoleChipComponent implements OnInit {
  @Input({
    required: true,
  })
  public role: AuthApi.AUTH_ROLE;

  public roleStr = '';

  public ngOnInit(): void {
    switch (this.role) {
      case EmployerApi.Role.EMPLOYER:
        this.roleStr = 'Employer';
        break;
      case EmployerApi.Role.OWNER:
        this.roleStr = 'Owner';
        break;
      case AdminApi.Role.DEVELOPER:
        this.roleStr = 'Developer';
        break;
      case AdminApi.Role.SUPER_ADMIN:
        this.roleStr = 'Super Admin';
        break;
      case AdminApi.Role.MARKETING:
        this.roleStr = 'Marketing';
        break;
      case AdminApi.Role.APPLICANT_SERVICE_AGENT:
        this.roleStr = 'Applicant Service Agent';
        break;
      case AdminApi.Role.CUSTOMER_SUCCESS_MANAGER:
        this.roleStr = 'Customer Success Manager';
        break;
      case ApplicantApi.Role.APPLICANT:
        this.roleStr = 'Applicant';
        break;
      case PromoterApi.Role.PROMOTER:
        this.roleStr = 'Promoter';
        break;
    }
  }
}
