import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'wh-s-profile-detail-about',
  imports: [CommonModule],
  template: `
    <div *ngIf="title" class="title-wrapper">
      <h4 class="wh-header-h4">{{ title }}</h4>
    </div>

    <div class="wrapper">
      @if (about) {
        <p class="wh-paragraph-md description-content" [innerHTML]="about"></p>
      } @else {
        <p class="wh-paragraph-md description-content">N/A</p>
      }
    </div>
  `,
  styles: [
    `
      @use 'shared-mixin' as mx;

      :host {
        display: inline-block;

        & > .title-wrapper {
          margin-top: 2rem;

          & > h4 {
            @include mx.df-screen(mobile) {
              font-size: 40px;
            }
          }
        }

        & > .wrapper {
          & > .description-content {
            &::ng-deep p {
              margin-block: 0.5rem;
              min-height: 8px;
            }

            &::ng-deep ul {
              margin-block-start: 0.5rem;
            }

            &::ng-deep ol {
              margin-block-start: 0.5rem;
            }

            &::ng-deep h1 {
              font-family: 'Bebas Neue', cursive;
              font-style: normal;
              font-weight: 400;
              font-size: 28px;
              line-height: 110%;
              margin-block-start: 1rem;
              margin-block-end: 0.5rem;
            }

            &::ng-deep h2 {
              font-family: 'Bebas Neue', cursive;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 110%;
              margin-block-start: 1rem;
              margin-block-end: 0.5rem;
            }

            &::ng-deep h3 {
              font-family: 'Bebas Neue', cursive;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 110%;
              margin-block-start: 1rem;
              margin-block-end: 0.5rem;
            }
          }
        }
      }
    `,
  ],
})
export class ProfileDetailAboutComponent {
  @Input({
    required: true,
  })
  public about: string;

  @Input()
  public title?: string;
}
