import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { skip } from 'rxjs';

@Component({
  selector: 'wh-s-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class DropdownComponent<T> implements ControlValueAccessor {
  @Input()
  public items: T[];

  /**
   * If whole object has to be bound leave empty
   */
  @Input()
  public bindValue: string;

  /**
   * Declare field of the object which has to be displayed
   */
  @Input()
  public bindLabel: string;

  @Input()
  public label: string;

  @Input()
  public multiple = false;

  @Input()
  public placeholder: string;

  @Input()
  public clearable = true;

  @Input()
  public variant: 'default' | 'success' | 'danger' = 'default';

  @Input()
  public required = false;

  @Input()
  public size: 'md' | 'lg' = 'md';

  @Input()
  public showLoading = false;

  @Input()
  public hasMessageWrapper = true;

  /**
   * Whether the input is disabled or not
   */
  @Input()
  public disabled = false;

  /**
   * Error label to be displayed in case danger variant is active
   */
  @Input()
  public errorLabel?: string = '';

  /**
   * What hint should be displayed next to the input?
   */
  @Input()
  public hint = '';

  @Output()
  public selectedItem = new EventEmitter<T>();

  @Output()
  public clear = new EventEmitter<T | null | undefined>();

  public value: T | null | undefined;
  public selectListControl = new FormControl('');
  public isFocused = false;
  public isOpen = false;
  public isSearchActive = false;

  constructor(
    private readonly cd: ChangeDetectorRef,
    public readonly loadingViewModel: LoadingViewModel,
  ) {}

  public clearValueAndEmit(): void {
    this.clear.emit(this.value);
    this.value = null;
  }

  /**
   * Adds md class to the host element
   */
  @HostBinding('class.md')
  public get isMidSize(): boolean {
    return this.size === 'md';
  }

  /**
   * Adds lg class to the host element
   */
  @HostBinding('class.lg')
  public get isLargeSize(): boolean {
    return this.size === 'lg';
  }

  /**
   * Adds default class to the host element
   */
  @HostBinding('class.default')
  public get isDefaultVariant(): boolean {
    return this.variant === 'default';
  }

  /**
   * Adds success class to the host element
   */
  @HostBinding('class.success')
  public get isSuccessVariant(): boolean {
    return this.variant === 'success';
  }

  /**
   * Adds danger class to the host element
   */
  @HostBinding('class.danger')
  public get isDangerVariant(): boolean {
    return this.variant === 'danger';
  }

  /**
   * Adds disabled class to the host element
   */
  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.disabled;
  }

  /**
   * Adds disabled class to the host element
   */
  @HostBinding('class.loading')
  public get isLoading(): boolean {
    return this.loadingViewModel.isLoading && this.showLoading;
  }

  public change = (_: T): void => {};

  public registerOnChange(fn: any): void {
    this.change = fn;
    this.selectListControl.valueChanges.pipe(skip(1)).subscribe(fn);
    this.cd.detectChanges();
  }

  public registerOnTouched(fn: any): void {}

  public writeValue(val: T): void {
    if (val) {
      this.value = val;
      this.change(this.value);
    }
  }

  public compareFn(item1: any, item2: any): boolean {
    if (!this.multiple) return false;

    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  public onSearch(searchEvent: { term: string; items: any[] }): void {
    if (searchEvent.term) {
      this.isSearchActive = true;

      return;
    }

    this.isSearchActive = false;
  }
}
